import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const OrderFailed = () => {
  return (
    <Container>
        <h2>¡Compra sin exito :( !</h2>
        <p>Lamentamos que no hayas podido procesar tu compra. Ponte en contacto con nosotros para solucionar tu problema <Link to={'/contacto'}>contactanos aqui</Link></p>
    </Container>
  )
}

const Container = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 width: 100%;
 max-width: 250px;

p{
    width: 500px;
    text-align: center;
}


@media screen and (max-width:650px) {
  display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 width: 300px;
}

`

export default OrderFailed