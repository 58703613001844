import React from "react";
import styled from "styled-components";
import afa from "../../img/logoAfa.png";
import star from "../../img/logoStar.jpeg";
import uni from "../../img/logoUni.png";
import xtre from "../../img/logoXtre2.png";
import nutri from "../../img/logoNutri.png";
import ena from "../../img/enaLogo.jpg";
import one from "../../img/logoOne.png";


const SlideLogo = () => {
  return (
    <Container>

      <h1 className="title-offer">Marcas</h1>
      <div className="slider">
        <div className="slide-track">
          <div className="slide">
            <img alt="Afa" src={afa}></img>
          </div>
          <div className="slide">
            <img alt="Universal" src={uni}></img>
          </div>
          <div className="slide">
            <img alt="Star Nutrition" src={star}></img>
          </div>
          <div className="slide">
            <img alt="NutriShop" src={nutri}></img>
          </div>
          <div className="slide">
            <img alt="Xtrenght" style={{backgroundColor:"black"}} src={xtre}></img>
          </div>
          <div className="slide">
            <img alt="One fit" src={one}></img>
          </div>
          <div className="slide">
            <img alt="Ena" src={ena}></img>
          </div>
          <div className="slide">
            <img alt="Afa" src={afa}></img>
          </div>
          <div className="slide">
            <img alt="Universal" src={uni}></img>
          </div>
          <div className="slide">
            <img alt="Star Nutrition" src={star}></img>
          </div>
          <div className="slide">
            <img alt="NutriShop" src={nutri}></img>
          </div>
          <div className="slide">
            <img alt="Xtrenght" style={{backgroundColor:"black"}} src={xtre}></img>
          </div>
          <div className="slide">
            <img alt="One fit" src={one}></img>
          </div>
          <div className="slide">
            <img alt="Ena" src={ena}></img>
          </div>
        </div>
      </div>
      
    </Container>
  );
};

const Container = styled.div`

.title-offer{
    padding: 40px;
    text-transform: uppercase;
}


.slider{
  width: 85vw;
  height: auto;
  margin: auto;
  overflow: hidden;
  padding: 40px;
}

.slider .slide-track{
  display: flex;
  animation: scroll 40s linear infinite;
  width: calc(200px*14);
  -webkit-animation:scroll 36s linear infinite ;
  align-items: center;
  gap: 30px;

}


.slider .slide{
  width: 200px;
}

.slider .slide img{
  width: 100%;
}

@keyframes scroll {
   0%{
    -webkit-transform:translateX(0);
    transform: translateX(0);
   } 
   100%{
    -webkit-transform:translateX(calc(-200px*7));
    transform: translateX(calc(-200px*7));
   } 
}


`;

export default SlideLogo;
