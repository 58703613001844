import React from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useState, useEffect } from "react";
import styled from "styled-components";
import Swal from 'sweetalert2'
import gift from '../../img/whey2.gif'

const URI = 'https://nutrinorth.up.railway.app/users/'; //direccion en la que se hacen peticiones 

const Register = () => {
    const [nombre, setNombre] = useState(''); //todo esto son valores que se registran en la base de datos
    const [contraseña, setContraseña] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [users, setUsers] = useState([]);
    const [nombreError, setNombreError] = useState('');
    const [contrasenaError, setContrasenaError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [telefonoError, setTelefonoError] = useState('');
    const [emailError, setEmailError] = useState('');
    const navigate = useNavigate();


        const navigateLogin = () => {
            navigate(`/login`);//envia al login
        }

    const navigateRegister = () => {
        navigate(`/register`);//envia al resgiter
    }

    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = async() => {//se obtienen todos los usuarios
        const res = await axios.get(URI)
        setUsers(res.data)
    }


    const validateForm = () => {
        let isValid = true;
    
        if (!nombre) {
            setNombreError('El nombre es obligatorio!!');
            isValid = false;
          } else {
            setNombreError('');
          }
      
          // Validar contraseña
          if (!contraseña) {
            setContrasenaError('La contraseña es obligatoria!!');
            isValid = false;
          } else {
            setContrasenaError('');
          }
    
        // Validar teléfono
        if (!telefono) {
          setTelefonoError('El teléfono es obligatorio!!');
          isValid = false;
        } else if (!/^\d{10}$/.test(telefono)) {
          setTelefonoError('El teléfono debe tener 10 dígitos!!');
          isValid = false;
        } else {
          setTelefonoError('');
        }
    
        // Validar email
        if (!email) {
          setEmailError('El email es obligatorio!!');
          isValid = false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
          setEmailError('Ingrese un email válido!!');
          isValid = false;
        } else {
          setEmailError('');
        }
    
        return isValid;
      };
    
      const store = async (e) => {
        e.preventDefault();
    
        if (validateForm()) {
          try {
            await axios.post(URI, {
              nombre: nombre,
              contraseña: contraseña,
              telefono: telefono,
              email: email,
            });
    
            Swal.fire({
              title: "Te has registrado exitosamente!!! , gracias por unirte a nuestra comunidad inicie sesion y empieza a comprar.",
              width: 700,
              padding: "3em",
              color: "white",
              background: "black",
              backdrop: `
                rgba(0,0,123,0.4)
                url('${gift}')
                left top
                no-repeat
                
              `
            });
            setNombre('');
            setContraseña('');
            setTelefono('');
            setEmail('');
            navigateLogin()
          } catch (error) {
            console.error('Error al registrar usuario', error);
            setSuccessMessage('Hubo un error al registrar el usuario. Inténtalo de nuevo.');
          }
        }
      };


      

    // const store = async (e) => { //hace un metodo post para ingresar el usuario a la base de taods
    //     e.preventDefault();
    //     await axios.post(URI, {nombre: nombre, contrasena: contrasena, telefono: telefono, email: email });
    //     navigateLogin();
    // }


    

    return (
        <Container>
             <div className="wrapper">
               <div className="form-wrapper sign-in">
                <form onSubmit={store} action="/auth" method="post">
                <h2>Registrarse</h2>
                <div className="input-group">
                    <input name="user" id="user" placeholder="Ej: Pedro"  type="text" value={nombre} onChange={(e) => Array.isArray(users) && users.some(user=>user.nombre===e.target.value)? navigateRegister() : setNombre(e.target.value)} />
                    <label>Usuario</label>
                    <span className="error-message">{nombreError}</span>

                </div>
                <div className="input-group">
                    <input name="pass" placeholder="Ej: tucontraseña"  type="password" value={contraseña} onChange={(e)=> setContraseña((e.target.value))} />
                    <label>Contraseña</label>
                    <span className="error-message">{contrasenaError}</span>

                </div>
                <div className="input-group">
                    <input name="pass"  placeholder="Ej: 1123455678" type="text" value={telefono} onChange={(e)=> setTelefono((e.target.value))} />
                    <label>Telefono</label>
                    <span className="error-message">{telefonoError}</span>
                </div>
                <div className="input-group">
                    <input name="pass"  placeholder="Ej: tuemail@gmail.com"  type="text" value={email} onChange={(e)=> setEmail((e.target.value))} />
                    <label>Email</label>
                    <span className="error-message">{emailError}</span>
                </div>
                <button value={'register'} type="submit">Registrase</button>
                      <div className="signUp-Link">
                        <p>Ya tienes una cuenta?,<span onClick={navigateLogin}>Iniciar sesion</span></p>  
                      </div>       
                </form>
               </div>
            </div>    

        </Container>
       
    )
}

const Container= styled.div`
  
  display: flex;
justify-content: center;
align-items: center;
min-height: 70vh;

.error-message{
    color: red;
}


.wrapper{
    position: relative;
    width: 400px;
    height: 700px;
    padding: 40px;
    background: red;
    background-color: #000;
    box-shadow: 0 0 50px var(--bittersweet);
    border-radius: 20px;
}

.from-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

}


h2{
    font-size: 30px;
    color: white;
    text-align: center;
}

.input-group{
    position: relative;
    margin: 30px 0;
    border-bottom: 2px solid #fff;

}

.input-group label{
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: .5s;
}

.input-group input{
   width: 320px;
   height: 40px;
   font-size: 16px;
   color: #fff;
   padding: 0 5px;
   background: transparent;
   border: none;
   outline: none;
}

.input-group input:focus~label,
.input-group input:valid~label{
    top: -5px;
}

button{
    position: relative;
    width: 100%;
    height: 40px;
    background: var(--bittersweet);
    box-shadow: 0 0 10px var(--bittersweet);
    font-size: 16px;
    color: black;
    font-weight: 500;
    cursor: pointer;
    border-radius: 30px;
    border: none;
    outline: none;
}

.signUp-Link{
    font-size: 14px;
    text-align: center;
    margin: 15px 0;
}

.signUp-Link p{
  color: white;
}

.signUp-Link p span{
    color: var(--bittersweet);
    cursor: pointer;
    font-weight: 500;
}

.signUp-Link p span:hover{
  text-decoration: underline;
}


`

export default Register;