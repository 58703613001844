import React from 'react';

import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import Product from './Product';
import styled from 'styled-components';

const URI = 'https://nutrinorth.up.railway.app/products/'; //aqui se hacen las peticiones

export const ProductsEdit = () => {

    const[products, setProducts] = useState([]) // aqui se guardan los productos
    useEffect(() => {
        getProduct()
    }, []);

    const getProduct = async () => {//aqui se hace la solicitud
        const res = await axios.get(URI)
        setProducts(res.data)
    }
    return (
        <Container>
            <div className="shopTitle">
                <h1>Edit Products</h1>
            </div>
            <div className="products"> 
                {products.map((product) => (
                    <Product  data={product} />// se muestran todos los productos que estan en el arreglo
                ))}
            </div>
        </Container>
    )
};

const Container= styled.div`
.products{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

`