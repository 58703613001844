import React from 'react'
import styled from 'styled-components'

const About = () => {
  return (
    <Container>
   

   <h1>Preguntas Frecuentes</h1>
<div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        ¿Cómo realizar una compra?
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        Para adquirir nuestros productos, simplemente selecciona el botón "Agregar al carrito" en el producto de tu elección. Después, completa el formulario de pago con tus datos y haz clic en "Pasar a pagar". Serás redirigido a la pasarela de pago de Mercado Pago, donde podrás realizar la transacción de forma segura con opciones de tarjeta de crédito, débito, Mercado Pago o efectivo. Si prefieres otro método de pago, no dudes en contactarnos de forma privada. Una vez realizada la compra, te contactaremos para confirmar los detalles.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
        ¿Cómo funcionan los envíos y puntos de retiro?
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        Ofrecemos envíos gratuitos a todo el país para que recibas tus productos en la comodidad de tu hogar. Además, si te encuentras en Buenos Aires Zona Norte o San Salvador de Jujuy, disponemos de puntos de retiro en esas áreas. Contáctanos para obtener más detalles o elegir la opción que mejor se adapte a tus necesidades.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
        ¿Cuáles son los medios de pago disponibles?
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        Aceptamos todos los medios de pago, y en nuestra plataforma, utilizamos la pasarela de pago de Mercado Pago para garantizar seguridad y comodidad. Esta opción te permite abonar con tarjeta de débito, crédito, efectivo, entre otras. Sin embargo, si prefieres realizar una transferencia, abonar en efectivo u optar por otros métodos, contáctanos de forma privada para coordinar la venta de acuerdo a tus preferencias.
      </div>
    </div>
  </div>
</div>

    <h1>Bienvenido a NutriNorth: Tu Destino Fitness</h1>

    <p>En NutriNorth, nos enorgullece ser tu aliado en el viaje hacia un estilo de vida más saludable y activo. Somos mucho más que una tienda de suplementos; somos una comunidad apasionada que comparte el compromiso de alcanzar objetivos y superar límites.</p>

    <p>NutriNorth nació de la pasión por el fitness y la búsqueda constante de proporcionar a nuestros clientes la mejor experiencia en nutrición deportiva. Iniciamos nuestro viaje con el sueño de crear un espacio donde cada persona, sin importar su nivel de experiencia, pueda encontrar el apoyo y los recursos necesarios para optimizar su rendimiento y bienestar.</p>

    <p>Nuestro catálogo de productos ha sido cuidadosamente seleccionado para incluir las marcas más confiables y los suplementos más efectivos del mercado. Desde proteínas y aminoácidos hasta preentrenamientos y vitaminas, ofrecemos una variedad que se adapta a tus necesidades y metas específicas. Nos comprometemos a proporcionarte productos que respalden tu rendimiento y aceleren tus resultados.</p>

    <p>En NutriNorth, entendemos que la salud y el bienestar son fundamentales para una vida plena. Más allá de ofrecer suplementos, nos esforzamos por educar y motivar a nuestra comunidad. Publicamos regularmente contenido informativo, consejos de entrenamiento y recetas saludables en nuestras redes sociales y blog. Creemos que una buena nutrición es la base para alcanzar tus metas fitness.</p>

    <p>Aunque nos concentramos en el norte de Argentina y Buenos Aires, enviamos nuestros productos a todas las provincias. Queremos que NutriNorth sea accesible para cada persona que busca mejorar su bienestar. Además, ofrecemos opciones de retiro en Jujuy y Buenos Aires para mayor comodidad.</p>

    <p>Nuestro equipo está formado por apasionados del fitness, nutricionistas y expertos en suplementación. Estamos aquí para ofrecerte asesoramiento personalizado y responder a todas tus preguntas. Creemos en la importancia de construir relaciones sólidas con nuestros clientes y compartir el viaje hacia el éxito juntos.</p>

    <p>Te invitamos a unirte a la creciente familia NutriNorth. Explora nuestro catálogo, participa en nuestras redes sociales y descubre el apoyo y la motivación que ofrecemos. Creemos que cada pequeño paso hacia un estilo de vida más saludable es una victoria, y queremos celebrar cada logro contigo. ¡NutriNorth es más que una tienda; es una comunidad comprometida con tu bienestar y éxito!</p>

    <p>Si tienes alguna pregunta, sugerencia o simplemente quieres conectarte con nosotros, no dudes en contactarnos. Puedes encontrarnos en Instagram como <a href="https://www.instagram.com/nutrinorthoficial/" target="_blank" rel="noopener noreferrer">@nutrinorthoficial</a> y estamos disponibles en el número de contacto: 11 5139 9129.</p>

    <p>En NutriNorth, estamos emocionados de ser parte de tu viaje hacia un estilo de vida más activo y saludable. ¡Bienvenido a una nueva era de bienestar con NutriNorth!</p>
  </Container>
  )
}

const Container=styled.div`
 margin: 70px;

`

export default About