import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import ItemProduct from "./ItemProduct";
import { styled } from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { FaFilter } from "react-icons/fa";


const URL = "https://nutrinorth.up.railway.app/products";

const Shop = () => {
 
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectBrand, setSelectBrand] = useState("");
  const [selectSabor, setSelectSabor] = useState("");
  const [categoryFilter, setCategoryFilter] = useState(""); 
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [isNavbarActive, setIsNavbarActive] = useState(false);

 
 
 
  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    const res = await axios.get(URL);
    setProducts(res.data);
    setFilteredProducts(res.data);
  };
 
  

  useEffect(() => {
    filterProducts();
   
  }, [selectSabor,selectBrand,categoryFilter])
  


 const filterProducts = () => {
  
    let filtered = products;
  
    if (selectBrand) {
      filtered = filtered.filter(p => p.marca === selectBrand);
    }
  
    if (categoryFilter) {
      filtered = filtered.filter(p => p.categoria === categoryFilter);  
    }
  
    if (selectSabor) {
      filtered = filtered.filter(p => p.sabor === selectSabor);
    }
  
    setFilteredProducts(filtered);
  }
  
  const onCategoryChange = (cat) => {
    setCategoryFilter(cat);
    filterProducts();
  }
  
  const onBrandChange = (brand) => {
    setSelectBrand(brand);
    filterProducts();  
  }
  
  const onSaborChange = (sabor) => {
    setSelectSabor(sabor);
    filterProducts();
  }

 

  const Filters = () => {
    return (
      <div className="filters">
        {selectSabor && (
          <div className="filter">
            Sabor: {selectSabor}
            <button className="deleteFilter" onClick={() => setSelectSabor("")}>X</button>
          </div>  
        )}

        {selectBrand && (
          <div className="filter">
           Marca: {selectBrand}
           <button className="deleteFilter" onClick={() => setSelectBrand("")}>X</button>
          </div>
        )}

        {categoryFilter && (
          <div className="filter">
            Categoría: {categoryFilter}
            <button className="deleteFilter" onClick={() => setCategoryFilter("")}>X</button>  
          </div>
        )}

        <button  onClick={clearFilters}>Resetear Filtros</button>
      </div>
    );
  }

  
  const clearFilters = () => {
    setSelectSabor("");
    setSelectBrand("");
    setCategoryFilter("");
    filterProducts();
  }

  const toggleNavbar = () => {
    setIsNavbarActive(!isNavbarActive);
  };

  const closeNavbar = () => {
    setIsNavbarActive(false);
  };

 

  return (
    <Container>
         <h1 className="title1">Suplementos</h1>
             
          <div className="filtro">
            <button className={`nav-open-btn ${isNavbarActive ? 'active' : ''}`} aria-label='open menu' onClick={toggleNavbar}>
                        <FaFilter/>
            </button>
          </div>

          {filtersVisible && <Filters />}

          <div className="filtros">
          <button onClick={() => setFiltersVisible(!filtersVisible)}>
          {filtersVisible ? 'Ocultar' : 'Mostrar'} Filtros Aplicados
          </button>
          </div>





      <div className="container-shop">  
        <div className="botoms">
        <h1 className="title">Filtros</h1>
            

            <ul className="dropdown">
              
              <li className="dropdown-list">
                <a className="dropdown-link">
                  
                  <span className="dropdown-span">Categorias</span>
                  <IoIosArrowDown className="arrow" />
                  <input type="checkbox" className="dropdown-check"></input>
                </a>
                <div className="dropdown-content">
                  <ul className="dropdown-sub">
                  
                  <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("combos")}>Combos</button>
                    </li>
                  
                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("BCAA")}>BCAA`S Aminoacidos</button>
                    </li>

                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("Proteinas")}>Proteinas</button>
                    </li>
                    
                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("Creatinas")}>Creatinas</button>
                    </li>

                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("Glutaminas")}>Glutaminas</button>
                    </li>

                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("Ganadores de peso")}>Ganadores de peso</button>
                    </li>

                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("Pre entreno")}>Pre entreno</button>
                    </li>

                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("Quemadores")}>Quemadores</button>
                    </li>

                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("Pro hormonales")}>Pro hormonales</button>
                    </li>


                    

                    
                  </ul>
                </div>
              </li>
             
              <li className="dropdown-list">
                <a className="dropdown-link">
                  
                  <span className="dropdown-span">Marcas</span>
                  <IoIosArrowDown className="arrow" />
                  <input type="checkbox" className="dropdown-check"></input>
                </a>
                <div className="dropdown-content">
                  <ul className="dropdown-sub">
                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onBrandChange("Star Nutrition")}>Star nutrition</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onBrandChange("Ena")}>Ena</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onBrandChange("NF Nutrition")}>Nf Nutrition</button>
                    </li>

                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onBrandChange("Xtrenght")}>Xtrenght</button>
                    </li>

                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onBrandChange("Universal")}>Universal</button>
                    </li>

                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onBrandChange("One Fit")}>One FIT Nutrition</button>
                    </li>

                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onBrandChange("Xbody")}>XBODY</button>
                    </li>

                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onBrandChange("Pulver")}>Pulver</button>
                    </li>
                  </ul>
                </div>
             
              </li>

              <li className="dropdown-list">
                <a className="dropdown-link">
                  
                  <span className="dropdown-span">Sabores</span>
                  <IoIosArrowDown className="arrow" />
                  <input type="checkbox" className="dropdown-check"></input>
                </a>
                <div className="dropdown-content">
                  <ul className="dropdown-sub">
                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Chocolate Vainilla Frutilla")}>Chocolate Vainilla Frutilla</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Chocolate Vainilla Cookies and Cream")}>Chocolate Vainilla Cookies and Cream</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Vainilla Chocolate")}>Vainilla Chocolate</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Watermelon")}>Watermelon</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Vainilla Chocolate Cookies and cream Frutilla")}>Vainilla Chocolate Cookies and cream Frutilla</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Chocolate y avellana Almendras")}>Chocolate y avellana Almendras</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Fruit Punch")}>Fruit Punch</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Grape atack Citrus sluch")}>Grape atack Citrus sluch</button>
                    </li>

                  

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Multifruta Naranja Pomelo")}>Multifruta Naranja Pomelo</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Red Energy Tropical Energy")}>Red Energy Tropical Energy</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Anana Multifruta Pomelo Cereza")}>Anana Multifruta Pomelo Cereza</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Caramel")}>Caramel</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Cereza Limon")}>Cereza Limon</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Uva Limon")}>Uva Limon</button>
                    </li>



                    
                  </ul>
                </div>
             
              </li>

              
            </ul>
        </div>
  
  
        
 
        
        <div className="container">
          
            {filteredProducts.map((product) => {
            return <ItemProduct key={product.id} data={product} />;
          })}
        </div>
      </div>  

      
      
      <div className="sidebar">
      
        <div className={`mobile-navbar ${isNavbarActive ? 'active' : ''}`}>
        
          <div className="wrapper">
            <button
              className="nav-close-btn"
              aria-label="close menu"
              onClick={closeNavbar}
            >
              <AiOutlineClose/>
            </button>
          </div>
            <h3>Filtros</h3>
            
          <ul className="navbar-list" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                
                <li className="dropdown-list">
                      <a className="dropdown-link">
                        
                        <span className="dropdown-span">Marcas</span>
                        <IoIosArrowDown className="arrow" />
                        <input type="checkbox" className="dropdown-check"></input>
                      </a>
                      <div className="dropdown-content">
                        <ul className="dropdown-sub">
                          <li className="dropdown-li">
                            <button className="dropdown__anchor" onClick={() => onBrandChange("Star Nutrition")}>Star nutrition</button>
                          </li>

                          <li className="dropdown-li">
                            <button className="dropdown__anchor" onClick={() => onBrandChange("Ena")}>Ena</button>
                          </li>

                          <li className="dropdown-li">
                            <button className="dropdown__anchor" onClick={() => onBrandChange("NF Nutrition")}>Nf Nutrition</button>
                          </li>

                          <li className="dropdown-li">
                          <button className="dropdown__anchor">Xtrengt</button>
                          </li>

                          <li className="dropdown-li">
                          <button className="dropdown__anchor">Universal</button>
                          </li>

                          <li className="dropdown-li">
                          <button className="dropdown__anchor">One FIT Nutrition</button>
                          </li>

                          <li className="dropdown-li">
                          <button className="dropdown__anchor">XBODY</button>
                          </li>

                          <li className="dropdown-li">
                          <button className="dropdown__anchor">Pulver</button>
                          </li>
                        </ul>
                      </div>
                  
                </li>

                <li className="dropdown-list">
                <a className="dropdown-link">
                  
                  <span className="dropdown-span">Categorias</span>
                  <IoIosArrowDown className="arrow" />
                  <input type="checkbox" className="dropdown-check"></input>
                </a>
                <div className="dropdown-content">
                  <ul className="dropdown-sub">
                  
                  <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("combos")}>Combos</button>
                    </li>
                  
                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("BCAA")}>BCAA`S Aminoacidos</button>
                    </li>

                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("Proteinas")}>Proteinas</button>
                    </li>
                    
                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("Creatinas")}>Creatinas</button>
                    </li>

                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("Glutaminas")}>Glutaminas</button>
                    </li>

                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("Ganadores")}>Ganadores de peso</button>
                    </li>

                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("Pre entreno")}>Pre entreno</button>
                    </li>

                    <li className="dropdown-li">
                    <button className="dropdown__anchor" onClick={() => onCategoryChange("Quemadores")}>Quemadores</button>
                    </li>

                    

                    
                  </ul>
                </div>
              </li>

              <li className="dropdown-list">
                <a className="dropdown-link">
                  
                  <span className="dropdown-span">Sabores</span>
                  <IoIosArrowDown className="arrow" />
                  <input type="checkbox" className="dropdown-check"></input>
                </a>
                <div className="dropdown-content">
                  <ul className="dropdown-sub">
                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Chocolate Vainilla Frutilla")}>Chocolate Vainilla Frutilla</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Chocolate Vainilla Cookies and Cream")}>Chocolate Vainilla Cookies and Cream</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Vainilla Chocolate")}>Vainilla Chocolate</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Watermelon")}>Watermelon</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Vainilla Chocolate Cookies and cream Frutilla")}>Vainilla Chocolate Cookies and cream Frutilla</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Chocolate y avellana Almendras")}>Chocolate y avellana Almendras</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Fruit Punch")}>Fruit Punch</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Grape atack Citrus sluch")}>Grape atack Citrus sluch</button>
                    </li>

                    

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Multifruta Naranja Pomelo")}>Multifruta Naranja Pomelo</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Red Energy Tropical Energy")}>Red Energy Tropical Energy</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Anana Multifruta Pomelo Cereza")}>Anana Multifruta Pomelo Cereza</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Caramel")}>Caramel</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Cereza Limon")}>Cereza Limon</button>
                    </li>

                    <li className="dropdown-li">
                      <button className="dropdown__anchor" onClick={() => onSaborChange("Uva Limon")}>Uva Limon</button>
                    </li>



                    
                  </ul>
                </div>
             
              </li>


          </ul>
        </div>
      </div>

    </Container>
  );
};

const Container = styled.div`
padding-top: 20px;


.container {
      display: flex;
      gap: 20px;
      flex-direction: row;
      max-width: 1200px;
      flex-wrap: wrap;
    }

.filtros{
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  height: 50px;
  
}

.filtros button{
  font-weight: 500;
}

.filters button{
  font-weight: 500;
}

.filters{
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  gap: 10px;
  
  
  
}

.filter{
  display: flex;
  flex-direction: row;
  box-shadow: var(--shadow-1);
  padding: 5px;
  gap: 4px;
  font-weight: 500;
  
}

.deleteFilter{
  background-color: rgba(226, 226, 226, 0.8);
  border-radius: 50%;
  font-size: 12px;
  font-weight: 600;
  
}


.container-shop{
  display: flex;
}
 
 .dropdown{
    display: none;
  }
 
  .title{
    display: none;
  }

  .title1{
    display: none;
  }
  

  .filtro button{
    
   
    position: fixed;
  }



  @media screen and (min-width:1000px){
    .nav-open-btn{
      display: none;
    }

    .title{
    display: block;
    font-weight: 500;
    padding: 10px;
    margin-left: 20px;
    font-size: 28px;
    
  }

  .title1{
    display: block;
    font-weight: 600;
    padding: 10px;
    margin-left: 20px;
    
    
  }

  .botoms{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    padding: 30px;
    /* box-shadow: var(--shadow-1); */
    padding: 30px;
    gap: 30px;
    
  }

    .container {
      display: flex;
      gap: 20px;
      flex-direction: row;
      max-width: 1200px;
      flex-wrap: wrap;
    }

    .dropdown {
      position: relative;
      display: inline-block;
      
      
    }

    .dropbtn {
      background-color: #fff;
      color: #000;
      padding: 10px 20px;
      font-size: 16px;
      border: 1px solid #ccc;
      cursor: pointer;
      display: flex;
      align-items: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .arrow {
      margin-left: 10px;
    }

  
  }

  @media screen and (max-width:550px){
  .filtros{
    display: flex;
    flex-direction: column;
  }

  .filters{
    display: flex;
    flex-direction: column;
  }

  .filter{
    width: 280px;
  }
}


  .dropdown-list{
  box-shadow: var(--shadow-1);
  margin-top: 30px;
  width: 100%;
 
}

.dropdown-link{
  padding: 1em .7em;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: .6em;
  position: relative;
  
 
}

.dropdown-list:has(:checked){
  --rows:1fr
}

.dropdown-check{
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.arrow{
  color: black;
}

.dropdown-span{
  color: black;
  font-weight: 500;
}

.dropdown-content{
   display: grid;
   grid-template-rows: var(--rows,0fr);
   transition: .3s grid-template-rows;
}

.dropdown-sub{
  overflow: hidden;
}

.dropdown-li{
  padding: 2px;
  list-style: none;
  
  color: black;
}

.dropdown__anchor{
  text-transform: uppercase;
  display: block;
  color: black;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
}





  .mobile-navbar{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 280px;
    width: 100%;
    padding: 25px 15px;
    z-index: 99;
    transform: translateX(100%);
    visibility: hidden;
    transition: 0.25s var(--cubic-in);
    background-color: var(--white);
    box-shadow: var(--shadow-1);
   }

   .mobile-navbar.active{
     visibility: visible;
     transform: translateX(0);
     /* transition: 0ms.4s var(--cubic-out) ; */
   }

   .mobile-navbar .wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block-end: 55px;
    
   }

   .mobile-navbar .navbar-link{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    padding-block: 5px;
    transition: var(--transition-1);
   }

`;

export default Shop;
