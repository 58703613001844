import React from "react";
import { Link } from "react-router-dom";
import { BsInstagram, BsFacebook } from "react-icons/bs";
import { FaCopyright } from "react-icons/fa";
import mp from "../../img/mercadopago@2x.png";
import amex from "../../img/amex@2x.png";
import pagofacil from "../../img/pagofacil@2x.png";
import rapipago from "../../img/rapipago@2x.png";
import naranja from "../../img/tarjeta-naranja@2x.png";
import visaDebito from "../../img/visadebit@2x.png";
import visa from "../../img/visa@2x.png";
import maestro from "../../img/maestro@2x.png";
import masterCard from "../../img/mastercard@2x.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Footer = () => {

  const navigate = useNavigate();

  const handleHomeButtonClick = (event) => {
    event.preventDefault();
  
    // Si ya estás en la página principal ("/"), realiza el scroll a la sección de combos
    if (window.location.pathname === '/') {
      const combosSection = document.getElementById('ofertas');
  
      if (combosSection) {
        combosSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // Si no estás en la página principal, redirige a la página principal ("/") y luego realiza el scroll
      navigate('/');
      setTimeout(() => {
        const combosSection = document.getElementById('ofertas');
  
        if (combosSection) {
          combosSection.scrollIntoView({ behavior: 'smooth' });
        }
      }, 400); // Ajusta el tiempo según sea necesario
    }
  };


  const handleHomeButtonClick2 = (event) => {
    event.preventDefault();
  
    // Si ya estás en la página principal ("/"), realiza el scroll a la sección de combos
    if (window.location.pathname === '/') {
      const combosSection = document.getElementById('combos');
  
      if (combosSection) {
        combosSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // Si no estás en la página principal, redirige a la página principal ("/") y luego realiza el scroll
      navigate('/');
      setTimeout(() => {
        const combosSection = document.getElementById('combos');
  
        if (combosSection) {
          combosSection.scrollIntoView({ behavior: 'smooth' });
        }
      }, 400); // Ajusta el tiempo según sea necesario
    }
  };

  return (
    <Container>
      <br />
      <br />
      <br />
      <footer>
        <div className="container">
          <div className="content__footer">
            <div className="profil">
              <div className="logo__area">
                <span className="logo__name">NutriNorth</span>
              </div>
              <div className="desc__area">
                <p>NutriNorth es una empresa con el objetivo y la mision de venderte suplementos de alta calidad y que te lleguen en el menor tiempo posible.</p>
              </div>
              <div className="social__media">
                <Link to="https://www.instagram.com/nutrinorthoficial/?hl=es-la"><BsInstagram/></Link>
                <Link to="https://www.facebook.com/profile.php?id=61554969554389"><BsFacebook/></Link>
              </div>
            </div>
            <div className="service__area">
              <ul className="service__header">
                <li className="service__name">Servicios</li>
                <li><Link to={'/productos'}>Tienda</Link></li>
                <li><Link onClick={handleHomeButtonClick}>Ofertas</Link></li>
                <li><Link onClick={handleHomeButtonClick2}>Combos</Link></li>
              </ul>

              <ul className="service__header">
                <li className="service__name">Informacion</li>
                <li><Link to={'/politica'}>Politicas de privacidad</Link></li>
                <li><Link to={'/terminos'}>Terminos y condiciones</Link></li>
                <li><Link to={'/sobre-nosotros'}>Preguntas frecuentes</Link></li>
                <li><Link to={'/sobre-nosotros'}>Sobre nosotros</Link></li>
              </ul>

              <ul className="service__header">
                <li className="service__name">Contacto</li>
                <li><Link>+54 (9) 11 5139-9129</Link></li>
                <li><Link>nutrinorthayuda@gmail.com</Link></li>
                <li><Link to='https://www.instagram.com/nutrinorthoficial/?hl=es-la'>@nutrinorthoficial </Link></li>
              </ul>
            </div>
          </div>
          <hr></hr>
          <div className="footer__bottom">
            <div className="copy__rigth">
            <FaCopyright />
            <span>2023 NutriNorth / Uli</span>
            </div>
            <div className="pay">
              <img alt=""  width='40' height='30' src={mp} />
              <img alt="" width='40' height='30' src={maestro} />
              <img alt="" width='40' height='30' src={masterCard} />
              <img alt="" width='40' height='30' src={rapipago} />
              <img alt="" width='40' height='30' src={pagofacil} />
              <img alt="" width='40' height='30' src={visa} />
              <img alt="" width='40' height='30' src={visaDebito} />
              <img alt="" width='40' height='30' src={naranja} />
              <img alt="" width='40' height='30' src={amex} />
            </div>
          </div>




        </div>

      </footer>
    </Container>
  );
};

const Container = styled.div`

@media screen and (max-width:768px) {

  .content__footer{
    flex-wrap: wrap;
    justify-content: left;
  }

  .service__area{
    flex-wrap: wrap;
    justify-content: left;

  }
  
  .service__header{
    margin-left: 0;
    margin-right: 40px;
  }

  .social__media{
    margin-bottom: 1rem;
  }

  .footer__bottom{
    flex-wrap: wrap;
    justify-content: center;
  }

}


@media screen and (max-width:510px){
  .service__header{
    margin-bottom: 8px;
  }

  .copy__right{
    margin-bottom: 8px;
  }
  



}




footer{
  
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 16px 70px -8px rgba(0,0,0,0.25);
  border-radius: 20px 20px 0 0;
  transition: background 0.3s linear;
}

.container{
  max-width: 1340px;
  padding: 30px 40px;
  margin: auto;
}

.content__footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

}

.perfil .logo__area{
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.logo__area .logo__name{
  font-size: 1.9rem;
  font-weight: 700;
  margin: 0 1rem;
  color: black;
}

.desc__area{
  max-width: 320px;
  margin-bottom: 1.25rem;
}

.desc__area p{
  color: gray;
  font-weight: 400;
  line-height: 28px;
  font-size: 18px;
}

.social__media{
  display: flex;
  padding-bottom: 10px;
}

.social__media a{
  color: gray;
  margin-right: 22px;
  font-size: 26px;
  

}

.social__media a:hover{
  color: black;
} 

.service__area{
  display: flex;
}

.service__header{
  margin-right: 40px;
}

li.service__name{
  color: black;
  font-weight: 600;
  font-size: 19px;
  
}

.service__header li a{
  color: gray;
  padding-bottom: 1px;
  font-weight: 400;
  font-size: 14px;
}

.service__header li a:hover{
  color: black;
}

.service__header li{
  margin-bottom: 10px;
}

hr{
  height: 1px;
  border: none;
  background-color: #828282;
  margin-bottom: 1rem;
}

.footer__bottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
}

.copy__right{
  display: flex;
  align-items: center;
}

.copy__right span{
  margin-left: 8px;
  font-weight: 400;
  letter-spacing: 0.135rem;

  
}


.pay{
  display: flex;
  flex-wrap: wrap;
  margin-left: 1.5rem;
  margin-top: 10px;
}







`;

export default Footer;
