import React, { useContext ,useState,useEffect} from 'react'
import { Link} from 'react-router-dom'
import { styled } from 'styled-components'
import axios from 'axios'
import {AiOutlineClose} from 'react-icons/ai'
import {AiOutlineUserAdd} from 'react-icons/ai'
import {BsSearch ,BsCart3 } from 'react-icons/bs'
import { CartContex } from '../../context/CartContex'
import { IoIosArrowForward,IoIosArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import logo from '../../img/miniLogo2.png'


const Navbar = () => {
 
  const URL = 'https://nutrinorth.up.railway.app/products/';
  const context = useContext(CartContex)
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const {getTotalCartAmount,cartItemCount} = useContext(CartContex)
  const total = getTotalCartAmount()
  const [products , setProducts] = useState([]);
  const [isNavbarActive, setIsNavbarActive] = useState(false);
  
  
  
  useEffect(()=> {
    getProducts()
  },[])
 
 const getProducts = async () => {
  const res = await axios.get(URL)
    setProducts(res.data)
 }

 const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term === '') {
      // Si el término está vacío, reiniciar sugerencias
      setSuggestions([]);
      return;
    }

    // Filtrar sugerencias en función del término de búsqueda
    const filteredSuggestions = products.filter((product) =>
      product.nombre.toLowerCase().includes(term.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };

  const navigate = useNavigate();

  const handleSelectSuggestion = (product) => {
    // Redirigir al detalle del producto o ejecutar la acción que desees
    // navigate(`/product/${product.id}`);
    // window.location.href = `/detail/${product.id}`;
    // Limpiar sugerencias y término de búsqueda después de seleccionar
    // window.location.href = `${window.location.origin}/detail/${product.id}`;
    navigate(`/detail/${product.id}`);
    setSuggestions([]);
    setSearchTerm('');
  };

 const toggleNavbar = () => {
    setIsNavbarActive(!isNavbarActive);
  };

  const closeNavbar = () => {
    setIsNavbarActive(false);
  };

  const texts = [
    'Descuentos en compras web',
    'Ofertas exclusivas para ti',
    'Envíos a todo el pais',
    // Agrega más textos según sea necesario
  ];

 useEffect(() => {
    const intervalId = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 4000); // Cambia el texto cada 3 segundos

    return () => clearInterval(intervalId);
  },[currentIndex]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + texts.length) % texts.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
  };
  

   

  const handleHomeButtonClick = (event) => {
    event.preventDefault();
  
    // Si ya estás en la página principal ("/"), realiza el scroll a la sección de combos
    if (window.location.pathname === '/') {
      const combosSection = document.getElementById('combos');
  
      if (combosSection) {
        combosSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // Si no estás en la página principal, redirige a la página principal ("/") y luego realiza el scroll
      navigate('/');
      setTimeout(() => {
        const combosSection = document.getElementById('combos');
  
        if (combosSection) {
          combosSection.scrollIntoView({ behavior: 'smooth' });
        }
      }, 1000); // Ajusta el tiempo según sea necesario
    }
  };


    return (
    <NavbarContainer>

          <header className='header'>
            <div className='alert'>
              
                <div className='container1'>
                  
                <div className='slider'>
      <div className='slider-content'>
        <div className='text'>{texts[currentIndex]}</div>
      </div>
      <div className='arrows'>
        <button className='arrow-btn' onClick={handlePrev}><IoIosArrowBack className='arrow'/></button>
        <button className='arrow-btn' onClick={handleNext}><IoIosArrowForward className='arrow'/></button>
      </div>
    </div>
                    
                    
                  
                </div>
            </div>

            <div className='header-top'>
                <div className='container2'>
                    <button className={`nav-open-btn ${isNavbarActive ? 'active' : ''}`} aria-label='open menu' onClick={toggleNavbar}>
                        <span className='line line-1'></span>
                        <span className='line line-2'></span>
                        <span className='line line-3'></span>
                    </button>

                    <div className='input-wrapper'>
                        <input className='search-field' type='search' name='search' placeholder='Buscar Producto...'
                        value={searchTerm}
                        onChange={handleSearch}/>
                        <button className='search-submit' aria-label='search'>
                       <Link to={'/productos'}> <BsSearch className='user2'/></Link>
                        </button>
                   
                                  {suggestions.length > 0 && (
                                      <ul className='suggestions'>
                                        {suggestions.map((product) => (

                                          <Link to={`/detail/${product.id}`}>
                                            <li className='itemSearch' key={product.id} onClick={() => handleSelectSuggestion(product)}>
                                              
                                             <div className='imgDiv'>
                                               <img alt={product.nombre} src={product.img}></img>
                                             </div>
                                              <h5>{product.nombre}</h5>
                                              <p>{product.precio}$</p>
                                            </li>
                                          </Link>
                                        ))}
                                      </ul>
                                    )}

                     </div>

                     <Link to={'/'} className='logo'>
                        <div className='imgLogo'>
                          <img alt='NutriNorth' src={logo}></img>
                        </div>
                        
                     </Link>

                     <div className='header-actions'>

                     <Link to={'/cart'}>
                     <button className='header-action-btn' aria-label='cart item'>
                     
                            <data className='btn-text' value='0'>{total > 0 ? total + '$' : '0.00$'}</data>
                           
                           <BsCart3  className='user'/>
                           
                           <span className='btn-badge'>{cartItemCount > 0 ? cartItemCount : '0'}</span>
                        </button>
                        </Link>
                  
                        
                    

                        {/* <button className='header-action-btn' aria-label='favorite item'>
                           <AiOutlineHeart className='user'/>
                           <span className='btn-badge'>0</span>
                        </button> */}

                    {!context.admin ? 
                       !context.logged? 
                        <button className='header-action-btn' aria-label='user'>
                          <Link to={'/login'}>
                           <AiOutlineUserAdd className='user'/>
                           </Link>
                        </button>
                        :
                        <button className='header-action-btn' aria-label='user'>
                        <span className='usuario'>Hola,  {context.user ? context.user.nombre : 'Usuario'}!</span>
                      </button>
                        :

                        <button className='header-action-btn' aria-label='user'>
                        <Link className='admin' to="/editInventory">Editar Productos </Link>
                        <Link className='admin' to="/editAdmin"> Perfil Admin </Link>
                      </button>
                        }

                        

                       

                        

                     </div>

                     <nav className='navbar'>
                        <ul className='navbar-list'>
                            <li>
                            <Link to='/' className='navbar-links has-after'>Inicio</Link>
                            </li>
                            <li>
                            <Link to='/productos' className='navbar-links has-after'>Suplementos</Link>
                            </li>
                            <li onClick={handleHomeButtonClick}>
                            <Link  onClick={handleHomeButtonClick} className='navbar-links has-after'>Combos</Link>
                            </li>
                            <li>
                            <Link to={'/sobre-nosotros'} className='navbar-links has-after'>Informacion</Link>
                            </li>
                            <li>
                            <Link to={'/contacto'} className='navbar-links has-after'>Contacto</Link>
                            </li>
                        </ul>

                      
                     </nav>
                     

                </div>
            </div>

          </header>

          <div className='sidebar'>
              <div className={`mobile-navbar ${isNavbarActive ? 'active' : ''}`} data-navbar>
                 <div className='wrapper'>
                    <Link to={'/'} className='logo'>
                    <div className='imgLogo'>
                          <img alt='NutriNorth' src={logo}></img>
                        </div>
                    </Link>

                    <button className='nav-close-btn' aria-label='close menu' onClick={closeNavbar}>
                        <AiOutlineClose className='user'/>
                    </button>
                 </div>
                 
                 <ul className='navbar-list' >
                    <li>
                        <Link className='navbar-link' to='/' >Inicio</Link>
                    </li>
                    <li>
                        <Link className='navbar-link' to='/productos' >Suplementos</Link>
                    </li>
                    <li onClick={handleHomeButtonClick}>
                        <Link onClick={handleHomeButtonClick} className='navbar-link' to='' >Combos</Link>
                    </li>
                    <li>
                        <Link to={'/sobre-nosotros'} className='navbar-link' >Informacion</Link>
                    </li>
                    <li>
                        <Link to={'/contacto'} className='navbar-link'>Contacto</Link>
                    </li>
                 </ul>

                 <ul>
                         
                          <li>
                            <Link to={'/login'}> <AiOutlineUserAdd className='userMobile'/></Link>
                          </li>
                        </ul>


              </div>

              <div className={`overlay ${isNavbarActive ? 'active' : ''}`} onClick={closeNavbar}></div>

          </div>
          


    
    </NavbarContainer>
  )
}

const NavbarContainer = styled.div`
  

  .userMobile{
    color: black;
    font-size: 26px;
  }
  
  .usuario{
  font-size: 19px;
  font-weight: 500;

}

.admin{
  font-size: 15px;
  color: black;

}

.imgLogo img{
  width: 100px;
  height: 100px;
}

 .user2{
  color: black;
 }

  .suggestions{
   width: 400px;
   z-index: 99;
   position: absolute;
   box-shadow: var(--shadow-1);
   background-color: white;
   
   padding: 0;
  }


  .imgDiv{
    background-color:#8080802e;
    object-fit: cover;
  }

.itemSearch h5{
  font-size: 18px;
  padding: 3px;
  color: black;
}

.itemSearch p{
  padding: 10px;
  font-size: 17px;
  color: black;
  font-weight: 600;
}

.itemSearch img{
  width: 100%;
  max-width: 200px;
  object-fit: cover;


}

.itemSearch{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3px;
  border: solid 1px #00000042;
  

}


   .slider {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slider-content {
  display: flex;
  opacity: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: opacity 0.5s ease-in-out;
}

.text {
  text-align: center;
  font-size: 9px;
  width: 100%;
  box-sizing: border-box;
  
  transition: opacity 0.5s ease-in-out ;
}

.arrows {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  
}

.arrow-btn {
 
  
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
}

   .arrow{
     font-size: 21px;
    color: white;
   }
   

   .navbar-link{
    color: black;
   }

   .user{
    font-size: 24px;
    color: black;
   }
   
   .header .input-wrapper , 
   .header-action-btn:not(:first-child),
   .navbar{display:none}

   .header-action-btn data{
    display: none;
   }

   .header-action-btn{
    position: relative;
    
   }

   .header-action-btn .btn-badge{
      position: absolute;
      font-size: 14px;
      top: 0;
      
      right: -1px;
      background-color: black;
      color: white;
      min-width: 20px;
      height: 20px;
      line-height: 1.4;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
   
    .header{
      position: fixed;
    padding-block-end: 80px;
    width: 100%;
    z-index:99;
    
   }

   .alert{
   
    background-color: black;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    margin: 0;
    letter-spacing: 1px;
    text-align: center;
    padding-block:15px ;
    color: white;
   }

   .header-top{
    position: relative; 
    bottom: 0;
    left: 0;
    width: 100%;
    padding-block: 26px;
    background-color: var(--white);
    z-index:4 ;
    height: 140px;
    border-bottom: 1px solid black;
   }

   .header-top.active{
    position: fixed;
    top: -80px;
    bottom: auto;
    transition: 0.5s ease;
    transform: translate(100%);
    box-shadow: var(--shadow-1);

   }



   .header-top.header-hide{
    box-shadow: none;
    transform: translateY(0);
   }

   .header-top .container2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
   }

   .nav-open-btn{
    width: 26px;
    display: grid;
    gap: 4px;
    padding-block:5px ;

   }

   .nav-open-btn .line{
    height: 3px;
    background-color: black;
    width: 24px;
   }

   .header-action-btn{
    font-size: 28px;
    transition: var(--transition-1);
    
   }

   .sidebar{
    z-index: 99;
   }
   
   .mobile-navbar{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    max-width: 290px;
    width: 100%;
    padding: 25px 40px;
    z-index: 100;
    transform: translateX(-100%);
    visibility: hidden;
    transition: 0.25s var(--cubic-in);
    background-color: var(--white);
    border-right: 1px solid black;
   }

   .mobile-navbar.active{
     visibility: visible;
     transform: translateX(0);
     /* transition: 0ms.4s var(--cubic-out) ; */
   }

   .mobile-navbar .wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block-end: 55px;
    
   }

   .mobile-navbar .navbar-link{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    padding-block: 5px;
    transition: var(--transition-1);
   }

   .overlay{
    background-color: transparent;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    pointer-events: none;
    transition:var(--transition-1) ;
   }

   .overlay.active{
    background-color:var(--black_50) ;
    pointer-events: all;
   }

   @media screen and (min-width:1200px) {
           
    .header .input-wrapper,.header-action-btn:not(:first-child),
    .navbar{
      display: block;
    }


    .text{
      font-size: 20px;
    }
    .nav-open-btn{
      display: none;
    }

    .header{
      padding-block-end: 60px;
      margin-block-end: 10px;
  

    }

    .header-top{
      position: unset;
      padding-block: 24px 0;
      
    }

    .header-top:is(.overlay.active, .header-hide){all:unset;}

    .header-top .container2{flex-wrap:wrap;}

    .search-field{
      width: 270;
      font-size: var(--fs-7);
      border: 2px solid var(--hoockers-green_20);
      border-radius: var(--radius-3);
      padding: 10px 20px;
      padding-inline-end: 40px;
      outline: none;
      transition: var(--transition-1);
    }

    .search-field::placeholder{color:var(--spanish-gray);}

    .search-field:focus{
      border-color: var(--black);
    }

    .header .input-wrapper{
      position: relative;
      margin-bottom: 30px;
      padding: 10px;
    }

    .header .search-submit{
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      font-size: 22px;

    }

    .header .logo{
      margin-inline-end: 60px;
    }

    .header-actions{
      display: flex;
      align-items: center;
      gap: 40px;
      padding-right: 50px;

    }

    .user{
      font-size: 36px;
    }

    .header-action-btn{
      position: relative;
      
    }

    .header-action-btn data{
    display: block;
    position: absolute;
    top: 0;
    left: -70px;
   }

    .header-action-btn .btn-badge{
      position: absolute;
      font-size: 18px;
      top: 0;
      right: -30px;
      background-color: black;
      color: white;
      min-width: 28px;
      height: 28px;
      line-height: 1.4;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .header-action-btn:last-child{
      display: flex;
      align-items: center;
      gap: 15px;
    }

    .header-action-btn .btn-text{
      
      font-weight: 700;
      margin-block-start: 12px;
      padding-right: 50px;
      
      font-size: 17px;
    }
  
    .navbar{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: var(--white);
      padding-block: 18px;
      z-index: 4;
      border-bottom: 1px solid black;
    }

    .navbar .navbar-list{
      display: flex;
      justify-content: center;
      gap: 45px;
      

    }

    .navbar .navbar-links{
    
      color: black;
      font-size: var(--fs-9);
      font-weight: 600;
      letter-spacing: 0.5px;
      position: relative;
      
    
    }


    .navbar-links:after{
      content: "";
      position: absolute;
      background-color: black;
      height: 3px;
      width: 0;
      left: 0;
      bottom: -10px;
      /* transform: scaleX(0); */
    }

    .navbar-links:hover:after{
      width: 100%;
      transition: var(--transition-1);
      transform-origin: right;
      /* transform: scaleX(1); */
    }

    .navbar-links:hover{
      color: gray;
    }

    

   

    .header-top.overlay.active .navbar{
      position: fixed;
      top: -80px;
      bottom: auto;
      padding-block: 28px;
      box-shadow: var(--shadow-1);
      transform: translateY(100%);
      transition: var(--transition-2);

    }

    .header .header-hide .navbar{
      box-shadow: none;
      transform: translateY();
    }


    .alert{
      height: 60px;
      margin: 0;
    }



   }

   
   


  
   


`

export default Navbar