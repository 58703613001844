import React, { useCallback, useContext,useEffect,useState } from 'react'
import { CartContex } from '../context/CartContex'
import { styled } from 'styled-components';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from 'react-router-dom';
import { BsFillCartXFill,BsFillCartPlusFill } from "react-icons/bs";

const ItemDetail = ({product}) => {
  
  

  const {addToCart, cartItems,removeFromCart} = useContext(CartContex)
  const [imgId, setImgId] = useState(1);


  const handleAddToCartClick = () => {
    addToCart(product.id);
  };

  const slideImage = useCallback(() => {
    const displayWidth = document.querySelector('.img-showcase img:first-child').clientWidth;
    document.querySelector('.img-showcase').style.transform = `translateX(${- (imgId - 1) * displayWidth}px)`;
  }, [imgId]);
  
  
  useEffect(() => {
    slideImage();
    
    const handleResize = () => {
      slideImage();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [imgId,slideImage]);

  const handleImageClick = (id) => {
    setImgId(id);
  };
  
  return (
    <Container>
       
     <h1 className='titleDetail'>Detalle del Producto</h1>

    <Link to={'/productos'}>
     <h4 className='back'><IoIosArrowBack />Ir a tienda </h4> 
     </Link>

    <div className='card-wrapper'>
      <div className='card'>
        <div className='product-imgs'>
          <div className='img-display'>
            <div className='img-showcase'>
              <img alt={product.nombre} src={product.img}></img>
              <img alt={product.nombre} src={product.img2}></img>
              <img alt={product.nombre} src={product.img3}></img>
              <img alt={product.nombre} src={product.img4}></img>
              
            </div>
          </div>
          <div className='img-select'>
            <div className='img-item'>
              <button onClick={() => handleImageClick(1)}>
                <img alt={product.nombre} src={product.img}></img>
              </button>
             </div>
             <div className='img-item'>
              <button onClick={() => handleImageClick(2)}>
                <img alt={product.nombre} src={product.img2}></img>
              </button>
             </div>
             <div className='img-item'>
              <button  onClick={() => handleImageClick(3)}>
                <img alt='' src={product.img3}></img>
              </button>
             </div>
             <div className='img-item'>
              <button  onClick={() => handleImageClick(4)}>
                <img alt='' src={product.img4}></img>
              </button>
             </div>
             
          </div>
        </div>
        <div className='product-content'>
          <h2 className='product-title'>{product.nombre}</h2>
          
          <div className='product-price'>
            <p className='new-price'><span>${product.precio}</span></p>
          </div>
         
          <div className='purchase-info'>
            <input  type='number' min='1' value={cartItems[product.id]}  />
            <button onClick={handleAddToCartClick} type='button' className='btnDetail'>
                Agregar al <BsFillCartPlusFill className='cart' />
            </button>
            <button onClick={()=> removeFromCart(product.id)} type='button' className='btnDetail'>
                Eliminar del <BsFillCartXFill className='cart' />
            </button>
          </div>


          <div className='product-detail'>
          <ul>
              <li> <IoIosCheckmarkCircle className='check' /> Sabor: <span>{product.sabor}</span></li>
              <li> <IoIosCheckmarkCircle  className='check'/> Stock: <span>En Stock</span></li>
              <li> <IoIosCheckmarkCircle className='check' /> Categoria: <span>{product.categoria}</span></li>
              <li> <IoIosCheckmarkCircle className='check' /> Envios: <span>Gratis</span></li>
            </ul>
            <h2>Descripcion:</h2>
            <p>{product.descripcion}</p>
           
          </div>

          

        </div>
      </div>
    </div>


    </Container>
  )
}


const Container = styled.div`
 line-height: 1.5;

.titleDetail{
  padding: 10px;
  
}
.cart{
  font-size: 25px;
}

.back{
  cursor: pointer;
  font-size: 15px;
  padding-left: 20px;
  color: black;
}

 input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

 .card-wrapper{
  max-width: 1100px;
  margin: 0 auto;
 }

 img{
  width: 100%;
  display: block;
 

 }

 .img-display{
  overflow: hidden;

 }

 .img-showcase{
  display: flex;
  width: 100%;
  transition: all 0.5s ease;
 }

 .img-showcase img{
  min-width: 100%;

 }

 .img-select{
  display:flex;
 }

 .img-item{
  margin: 0.3rem;
  width: 100%;
 }

.img-item:nth-child(1),.img-item:nth-child(2),.img-item:nth-child(3){
  margin-right: 0;
}

.img-item:hover{
  opacity: 0.8;
}

.product-content{
  padding: 2rem 1rem;
}

.product-title{
  font-size: 2rem;
  text-transform: capitalize;
  font-weight: 700;
  position: relative;
  margin: 1rem 0;
}

.product-title::after{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  width: 80px;
  background: #12263a;
}

.product-price{
  margin: 1rem 0 ;
  font-size: 1.2rem;
  font-weight: 700;
}

.product-detail h2{
  text-transform: capitalize;
  padding-bottom: 1rem;
}

.product-detail p{
  font-size: 0.9rem;
  padding: 0.3rem;
  opacity: 0.8;
}


.product-detail ul li{
  margin: 0;
  list-style: none;
  display: flex;
 padding-left: 1.7rem;
 margin: 0.4rem 0;
 font-weight: 600;
 opacity: 0.9;

}

.check{
  font-size: 1.2rem;
}


.purchase-info{
  margin: 1.5rem 0;
 
}

.purchase-info input, 
.purchase-info .btnDetail{
  border: 1.5px solid #ddd;
  border-radius: 25px;
  text-align: center;
  padding: 0.45rem 0.8rem;
  outline: 0;
  margin-right: 0.2rem;
  margin-bottom: 1rem;
  font-size: 16px;
}

.purchase-info input{
  width: 60px;
}


@media screen and (min-width: 822px){
  .card{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 1.5rem;
  }
  
  .purchase-info{
    display: flex;
  }


  .card-wrapper{
    
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .product-imgs{
    display: flex;
    flex-direction: column;
    
  }

  .product-content{
    padding-top: 0;
  }

}




`


export default ItemDetail