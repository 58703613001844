import React from 'react'
import { toast ,ToastContainer} from 'react-toastify'
import { AiOutlineWhatsApp, AiOutlinePhone, AiOutlineMail, AiOutlineEnvironment} from 'react-icons/ai'
import styled from 'styled-components'
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import emailjs from 'emailjs-com';



emailjs.init('XAoA5BLMDmOUyOtPg');



const Contact = () => {

    const notify = () => toast.success('Mensaje enviado, te contactamos pronto!!!')



    const [formData, setFormData] = useState({
        nombre: '',
        email: '',
        telefono: '',
        mensaje: '',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Realiza tus validaciones aquí (puedes usar el estado formData)
    
        // Llama a la función para enviar el correo electrónico
        sendEmail(formData);
    
        // Notifica al usuario que el mensaje fue enviado
        notify();
      };
    
      const nombreMail= formData.nombre
  const numero=formData.telefono
  const mensaje=formData.mensaje
  const email = formData.email


      const sendEmail = async (formData) => {
        const serviceId = 'service_c4gh34o';  // Reemplaza con tu Service ID de Email.js
        const templateId = 'template_i4i68vq';  // Reemplaza con tu Template ID de Email.js
    
        try {
          await emailjs.send(serviceId, templateId,{
               nombre:nombreMail,
               telefono:numero,
               email:email,
               mensaje:mensaje
          });
    
          console.log('Correo electrónico enviado con éxito');
        } catch (error) {
          console.error('Error al enviar el correo electrónico:', error);
        }
      };
    return (
        <ContactMail>
            <h3> Contacto</h3>
           <ul className='listInfo'>
                   <li>Lunes a Domingos siempre disponibles!!</li>
               <li> <AiOutlineWhatsApp className="footer-icon"></AiOutlineWhatsApp>+54 11 5139-9129</li>
               <li> <AiOutlinePhone className="footer-icon"></AiOutlinePhone>+54 9 11 5139-9129</li>
               <li> <AiOutlineMail className="footer-icon"></AiOutlineMail>nutrinorthayuda@gmail.com</li>
               <li> < AiOutlineEnvironment className="footer-icon"></ AiOutlineEnvironment>Zona norte Buenos Aires - Liberta 1910 Florida</li>
               <li> < AiOutlineEnvironment className="footer-icon"></ AiOutlineEnvironment>San Salvador de Jujuy - General belgrano 1404</li>
            </ul>
    
            <h4>Te Contactamos nosotros</h4>
    
            <div className='contactoCaja'>
        <form autoComplete='off' onSubmit={handleSubmit}>
          <input
            type='text'
            name='nombre'
            placeholder='nombre'
            className='nombre'
            value={formData.nombre}
            onChange={handleChange}
            required
          />
          <input
            type='email'
            name='email'
            placeholder='ingrese tu correo'
            className='email'
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type='phone'
            name='telefono'
            placeholder='telefono'
            value={formData.telefono}
            onChange={handleChange}
          />
          <textarea
            name='mensaje'
            placeholder='Mensaje'
            className='mensaje'
            value={formData.mensaje}
            onChange={handleChange}
            required
          />
          <button className='btn-enviar' type='submit'>Enviar Mensaje</button>
        </form>
      </div>
           <ToastContainer/>
        </ContactMail>
      )
    }
    
    const ContactMail= styled.div`
    display: flex;
    flex-direction: column;
    padding: 9rem;
    align-items: center;
    
    @media screen and (max-width:500px) {
          
       h3{
        padding-top: 4rem;
       }
        
        padding: 5rem;
    
    
        .contactoCaja form{
        display: flex;
        flex-direction: column;
        max-width: 300px;
         .mensaje{
            max-width: 300px;
            min-width: 300px;
            min-height: 200px;
         } 
       
      }
    }
    
    
    
    .listInfo{
        display: flex;
        flex-direction: column;
        padding: 1rem;
        margin: 2rem;
    
        li{
            padding: 1rem;
        }
    }
    
    
    .contactoCaja form{
        display: flex;
        flex-direction: column;
        margin: 1.5rem;
        input{
            padding: 0.4rem;
            margin-top: 4rem;
            letter-spacing: 1.15px;
            border-radius: 5px;
            border: 0.5px solid rgb(187,187,187) ;
            color: gray;
            background: none;
            outline: none;
        }
        textarea{
            margin-top:3rem ;
            max-width: 530px;
            max-height: 150px;
            min-height: 140px;
            min-width: 530px;
            letter-spacing: 1.15px;
            border-radius: 5px;
            border: 0.5px solid rgb(187,187,187) ;
            color: gray;
            background: none;
            outline: none;
        }
        .btn-enviar{
            margin-top: 3rem;
            letter-spacing: 1.15px;
            border-radius: 5px;
            border: 0.5px solid rgb(187,187,187) ;
            color: gray;
            background: none;
            outline: none;
            padding: 0.4rem;
        }
    }
    
    `

export default Contact