import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import ItemDetail from './ItemDetail';
import axios from 'axios';



const ItemDetailContainer = () => {
    
    const URL = 'https://nutrinorth.up.railway.app/products/';

   
   
    const {id} = useParams()

    
    
  
    const [product , setProducts] = useState([]);
    useEffect(()=> {
      getProducts()
    },[])
   
   const getProducts = async () => {
    const res = await axios.get(URL + id)
      setProducts(res.data)
   }


     return (
    <div>
        
         <ItemDetail product={product} />
      
         
       
                
       
    </div>
  )
}

export default ItemDetailContainer