import { useParams } from 'react-router-dom';
import { MdOutlineContentCopy } from "react-icons/md";
import styled from 'styled-components';
import logo from '../../img/whatsapp.jpg'
import logo2 from '../../img/instagram.jpg'


const CompraExitosa = () => {
  const { numeroDeOrden } = useParams();

  // Generar número de orden aleatorio
  const generarNumeroDeOrden = () => {
    const numeroAleatorio = Math.floor(Math.random() * 1000000) + 1;
    return `ORD-${numeroAleatorio}`;
  };

  const nuevoNumeroDeOrden = generarNumeroDeOrden();

  const copiarNumeroDeOrden = () => {
    // Copiar el número de orden al portapapeles
    navigator.clipboard.writeText(nuevoNumeroDeOrden)
      .then(() => {
        alert('Número de orden copiado al portapapeles.');
      })
      .catch((error) => {
        console.error('Error al copiar el número de orden:', error);
      });
  };

  const abrirChatWhatsApp = () => {
    // Reemplaza '123456789' con tu número de teléfono de WhatsApp
    const numeroWhatsApp = '5491151399129';
    const mensajeWhatsApp = `¡Hola! Tengo una compra hecha,  este es mi numero de orden: (${nuevoNumeroDeOrden}).`;
    const urlWhatsAppWeb = `https://web.whatsapp.com/send?phone=${numeroWhatsApp}&text=${encodeURIComponent(mensajeWhatsApp)}`;
    window.open(urlWhatsAppWeb, '_blank');
  };

  const abrirChatInstagram = () => {
    // Reemplaza 'nombre_usuario' con tu nombre de usuario de Instagram
    const nombreUsuarioInstagram = 'nutrinorthoficial';
    const urlInstagram = `https://www.instagram.com/${nombreUsuarioInstagram}`;
    window.open(urlInstagram, '_blank');
  };

  return (
    <Container>
      <h2>¡Compra Exitosa!</h2>
      <p>Gracias por tu compra. Tu número de orden es: {numeroDeOrden}</p>
      <div className='orden'>
        <p>{nuevoNumeroDeOrden}<MdOutlineContentCopy onClick={copiarNumeroDeOrden} /> </p>
        <p>Envia tu numero de orden al chat que mas comodo te quede.</p>
      </div>
      
      

      {/* Agrega los cuadros con los logos de WhatsApp e Instagram */}
      <div className='img'>
        <img src={logo} width={'300px'} alt="WhatsApp" onClick={abrirChatWhatsApp} />
      
      
        <img src={logo2}  width={'300px'} alt="Instagram" onClick={abrirChatInstagram} />
      </div>
    </Container>
  );
};


const Container = styled.div`
 display: flex;
 align-items: center;
 flex-direction: column;

.img{
  display: flex;
  gap: 5px;
  cursor: pointer;
}

.orden p{
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

`

export default CompraExitosa