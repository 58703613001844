import React from "react";
import styled from "styled-components";
import foto from "../../img/bannerOficial.jpg";
import foto2 from "../../img/banner2.jpg";
import {FaShippingFast} from 'react-icons/fa'
import {MdOutlineSupportAgent} from 'react-icons/md'
import {BsCreditCard2FrontFill} from 'react-icons/bs'
import {BiSolidOffer} from 'react-icons/bi'
import {IoLogoWhatsapp} from 'react-icons/io'
import CarouselOffer from "../../components/carouselOffer/CarouselOffer";
import SlideLogo from "../../components/sliderLogo/SlideLogo";
import { Link } from "react-router-dom";
import wasap from '../../img/WhatsApp_icon.png'

const Home = () => {


  // const abrirChatWhatsApp2 = () => {
  //   // Reemplaza '123456789' con tu número de teléfono de WhatsApp
  //   const numeroWhatsApp = '5491151399129';
  //   const mensajeWhatsApp = `¡Hola! Estoy interesado en conocer más sobre los suplementos disponibles en tu tienda. ¿Puedes proporcionarme información adicional y recomendaciones?`;
  //   const urlWhatsAppWeb = `https://web.whatsapp.com/send?phone=${numeroWhatsApp}&text=${encodeURIComponent(mensajeWhatsApp)}`;
  //   window.open(urlWhatsAppWeb, '_blank');
  // };


  const abrirChatWhatsApp = () => {
    // Reemplaza '123456789' con tu número de teléfono de WhatsApp
    const numeroWhatsApp = '5491151399129';
  
    // Puedes personalizar el mensaje según tus necesidades
    const mensajeWhatsApp = `¡Hola! Estoy interesado en conocer más sobre los suplementos disponibles en tu tienda. ¿Puedes proporcionarme información adicional y recomendaciones?`;
  
    // Construye el enlace con el esquema 'whatsapp://send'
    const urlWhatsApp = `whatsapp://send?phone=${numeroWhatsApp}&text=${encodeURIComponent(mensajeWhatsApp)}`;
  
    // Intenta abrir la aplicación de WhatsApp
    window.location.href = urlWhatsApp;
  };
  
return (
    
    <ContainerHome>

      <div className="icono-whatsapp">
        <Link><img onClick={abrirChatWhatsApp} className="boton" src={wasap}></img></Link>
      </div>

    
    
 {/* Carrusel */}
      <div id="carouselExample" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <Link to={'/productos'}>
            <img src={foto} className="d-block w-100" alt="Ir a Productos"/>
            </Link>
          </div>
          
          <div className="carousel-item">
            <Link to={`/detail/3`}>
            <img src={foto2} className="d-block w-100 img-slider" alt="Ir a combo"/>
            </Link>
          </div>
          
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>




  <CarouselOffer/>

<br/>

{/* Servicios  */}

<section className="home-wrapper-2 py-5">
  <div className="container-xxl">
    <div className="row">
      <div className="col-12">
        <div className="services d-flex align-items-center justify-content-between ">
          <div className="d-flex align-items-center gap-30">
            <FaShippingFast className="servicesLogo"/>
            <div>
              <h6 className="h3-services">Envios a todo el pais</h6>
              <p className="text-services mb-0">Envios Gratis!!!</p>
            </div>
          </div>

          <div className="d-flex align-items-center gap-30">
            <MdOutlineSupportAgent className="servicesLogo"/>
            <div>
              <h6 className="h3-services">Asesoramiento</h6>
              <p className="text-services mb-0">Asesoramiento para tu compra</p>
            </div>
          </div>

          <div className="d-flex align-items-center gap-30">
            <IoLogoWhatsapp className="servicesLogo"/>
            <div>
              <h6 className="h3-services">Soporte por Whatssapp</h6>
              <p className="text-services mb-0">Siempre disponibles</p>
            </div>
          </div>

          <div className="d-flex align-items-center gap-30">
            <BiSolidOffer className="servicesLogo"/>
            <div>
              <h6 className="h3-services">Combos y ofertas </h6>
              <p className="text-services mb-0">Combos precios increibles</p>
            </div>
          </div>

          <div className="d-flex align-items-center gap-30">
            <BsCreditCard2FrontFill className="servicesLogo"/>
            <div>
              <h6 className="h3-services">Todos los medios de pago</h6>
              <p className="text-services mb-0">Pagos 100% seguros</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<SlideLogo/>

</ContainerHome>
  );
};

const ContainerHome = styled.div`

@media screen and (max-width:1100px) {
  .services{
    flex-direction: column;
  }
  
  .text-services{
    font-size: 13px;
  }

  .servicesLogo{
  font-size: 60px;
}



}

@media screen and (min-width:801px) {
  
  .h3-services{
    font-size: 20px;
  }


  .servicesLogo{
  font-size: 120px;
}

 .services{
  gap: 160px;
 }   

.carousel-item img{
  height: 1000px;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 40px;

}

/* .icono-whatsapp{
  display: none;
} */



}


@media screen and (max-width:1600px) {

  .servicesLogo{
  font-size: 70px;
}

.h3-services{
    font-size: 15px;
  }



}

.boton{
  width: 80px;
}

.icono-whatsapp{
  position: fixed;
    z-index: 99;
    right: 25px;
    transition: 0.3s;
    padding-right: 80px;
    bottom: 25px;
}



.h3-services{
  font-weight: 700;
  
}

.gap-10{ 
           gap: 10px;
   }

   .gap-15{
    gap: 15px;
   }

   .gap-30{
    gap: 30px;
   }


.home-wrapper-2{
  background-color: #e1e1e1;

}

.services{
  gap: 60px;
  padding: 20px;
}




`;

export default Home;
