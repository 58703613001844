import React,{useContext, useEffect, useState} from 'react'
import { CartContex } from '../../context/CartContex'
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IoIosArrowUp , IoIosArrowDown } from "react-icons/io";
import emailjs from 'emailjs-com';
import { IoAlertCircleSharp } from "react-icons/io5";



emailjs.init('XAoA5BLMDmOUyOtPg');

const URL =  'https://nutrinorth.up.railway.app/products/';

 const Cart = () => {
    
  
  const {cartItems=[] , getTotalCartAmount,addToCart ,removeFromCart,removeFromCart2} = useContext(CartContex);
  const totalAmount = getTotalCartAmount()
  const [showCheckout, setShowCheckout] = useState(false);
  const [products , setProducts] = useState([])
  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    email: '',
    phone: '',
    option: '',
    location: '',
    address: '',
    province: '',
    detail:'',
    barrio:''
  });


  
  const nombreMail= formData.name
  const numero=formData.phone
  const option=formData.option
  const emailJs = formData.email
  const dire = formData.address
  const provincia = formData.province
  const retiro = formData.location
  const detail = formData.detail
  const barrio = formData.barrio
  


  useEffect(() => {
      getProducts()
      
  },[])
 
  const getProducts = async () => {
    const res = await axios.get(URL)
    setProducts(res.data)
  }

  const handleCheckout2 = () => {
    // Aquí puedes hacer lo que necesitas al hacer clic en Checkout
    setShowCheckout(true);
  };


  


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    
  };

 const handleCheckout = async (e) => {
    e.preventDefault();
  
    try {
      const formData = new FormData(e.target);
      const checkoutData = {};
  
      formData.forEach((value, key) => {
        checkoutData[key] = value;
      });
  
      // Validación de datos (puedes mejorarla según tus necesidades)
      if (!validateFormData(checkoutData)) {
        alert("Por favor, ingrese datos válidos.");
        return;
      }
  
      // Obtén los detalles de los productos desde el carrito
      const productsData = Object.keys(cartItems).map((productId) => {
        const product = products.find((p) => p.id === parseInt(productId, 10));
  
        // Verifica si el producto existe y tiene una cantidad mayor que cero
        if (product && cartItems[productId] > 0) {
          return {
            id: productId,
            name: product.nombre,
            quantity: cartItems[productId],
            // Otros detalles del producto según tu modelo de datos
          };
        }
  
        return null;
      })
      .filter((product) => product !== null); // Elimina elementos nulos del array
  
      // Imprime los detalles de los productos en la consola
      console.log(productsData);
  
      // Envía el correo electrónico antes de la llamada a la pasarela de pago
      await sendEmail(formData, totalAmount, productsData);
  
      // Aquí puedes hacer la llamada a tu pasarela de pago
      axios.post('https://nutrinorth.up.railway.app/mp', { totalAmount, checkoutData })
        .then((res) => {
          window.location.href = res.data.response.body.init_point;
        })
        .catch((error) => {
          console.error("Error al procesar el pago:", error);
          alert("Ocurrió un error al procesar el pago. Por favor, inténtelo nuevamente.");
        });
    } catch (error) {
      console.error("Error during handleCheckout:", error);
      alert("Ocurrió un error al procesar el checkout. Por favor, inténtelo nuevamente.");
    }
  };



  const validateFormData = (data) => {
    // Implementa tu lógica de validación aquí
    // Puedes usar expresiones regulares u otras formas de validar
  
    // Ejemplo: Validar email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(data.email)) {
      return false;
    }
  
    // Otros campos...
  
    return true;
  };




  const sendEmail = async (formData,totalAmount,productsData) => {
    const serviceId = 'service_c4gh34o';  // Reemplaza con tu Service ID
    const templateId = 'template_otsgxm2';  // Reemplaza con tu Template ID

    
    try {
      // Construye una cadena de texto con la información de los productos
      const productsText = productsData
        .map(product => `- Producto: ${product.name}, Cantidad: ${product.quantity}`)
        .join('\n');
  
      // Agrega la información de los productos al cuerpo del correo
      formData.productInfo = productsText;

   
      await emailjs.send(serviceId, templateId, {
        name: nombreMail,
        email: emailJs,
        phone: numero,
        totalAmount: totalAmount,
        option:option,
        address:dire,
        provincia:provincia,
        retiro:retiro,
        detail:detail,
        barrio:barrio,
        products:formData.productInfo

        // Agrega otros campos según tus necesidades
      });

      console.log('Correo electrónico enviado con éxito');
    } catch (error) {
      console.error('Error al enviar el correo electrónico:', error);
    }

    
  };

  
   return (
    <Container>
    {totalAmount > 0 ?
    <>
         <h1 className='titleCart'>Carrito</h1>
       <div className='small-container cart-page'>
        <table>
          <tr>
            <th>Producto</th>
            <th>Cantidad</th>
            <th>Precio</th>
          </tr>
          {products.map((product)=>{
           if(cartItems[product.id] !== 0){
            return <>
          <tr>
            <td>
              <div className='cart-info'>
               <img width={'240px'} src={product.img}></img>
                <div>
                  <p>{product.nombre}</p>
                  <small>Precio: ${product.precio}</small>
                  <Link onClick={() => removeFromCart2(product.id)}>Eliminar</Link>
                </div>
              </div>
            </td>
            <td>
              <div className='input'>
                
                <input type='number' value={cartItems[product.id]} />
                <div className='botoms'>
                  <button onClick={()=> addToCart(product.id)}><IoIosArrowUp /></button>
                  <button onClick={()=> removeFromCart(product.id)}><IoIosArrowDown /></button>
                </div>
              </div>
            </td>
            <td>${product.precio * cartItems[product.id]}</td>
          </tr>
          </>
           }
           })}
        </table>

        {showCheckout ? <>
          <div className="checkout-form">
              <h2>Datos para el Checkout</h2>
              <div className='alert1'>
                <IoAlertCircleSharp className='alert2' /> <h3 className='alertcheck'>Completa los datos como estan en los ejemplos!!</h3>
              </div>
              <div className='alert1'>
                <IoAlertCircleSharp className='alert2' /> <h3 className='alertcheck'>Te pediremos los sabores de los productos luego del pago!!</h3>
              </div>
              <form onSubmit={handleCheckout}>
                {/* Agrega más campos según tus requisitos */}
                <label>
                  Nombre:
                  <input placeholder='EJ: Pedro ...' className='inputCheck' type="text" name="name" value={formData.name} onChange={handleInputChange} required />
                </label>
                <label>
                  Apellido:
                  <input placeholder='EJ: David ...' className='inputCheck' type="text" name="lastName" value={formData.lastName} onChange={handleInputChange} required />
                </label>
                <label>
                  Email:
                  <input placeholder='EJ: usuario@gmail.com ...' className='inputCheck' type="email" name="email" value={formData.email} onChange={handleInputChange} required />
                </label>
                <label>
                  Teléfono (sin espacios):
                  <input placeholder='EJ:1131121031  ...' className='inputCheck' type="tel" name="phone" pattern="[0-9]{10}" value={formData.phone} onChange={handleInputChange} required />
                </label>
                <label>
                  Opción:
                  <select name="option" value={formData.option} onChange={handleInputChange} required>
                    <option value="">Seleccione una opción</option>
                    <option value="retirar">Retirar</option>
                    <option value="envios">Envíos</option>
                  </select>
                </label>
                {formData.option === 'retirar' && (
                  <div>
                    <label>
                      Ubicación:
                      <select name="location" value={formData.location} onChange={handleInputChange} required>
                        <option value="">Seleccione una ubicación</option>
                        <option value="Buenos Aires Florida">Buenos Aires Florida</option>
                        <option value="Jujuy Capital">Jujuy Capital</option>
                      </select>
                    </label>
                  </div>
                )}
                {formData.option === 'envios' && (
                  <div className='envios'>
                    <label>
                      Dirección:
                      <input className='inputCheck' type="text" name="address" value={formData.address} onChange={handleInputChange} placeholder='EJ: Arenales 1459' required />
                    </label>
                    <label>
                      Barrio o localidad:
                      <input className='inputCheck' type="text" name="barrio" value={formData.barrio} onChange={handleInputChange} placeholder='EJ: Pilar ,Vicente lopez,Moron,etc' required />
                    </label>
                    <label>
                      Mas detalles:
                      <input className='inputCheck2' name='detail' value={formData.detail} onChange={handleInputChange} type='text' placeholder='EJ:es en una casa piso 2 timbre A, es un depto piso 3 tiembre 1 ...'></input>
                    </label>
                    <label>
                      Provincia:
                      <select name="province" value={formData.province} onChange={handleInputChange} required>
                        <option value="">Seleccione una provincia</option>
                        <option value="Buenos Aires">Buenos Aires</option>
                        <option value="Cordoba">Cordoba</option>
                        <option value="Jujuy">Jujuy</option>
                        <option value="Tucuman">Tucuman</option>
                        <option value="Catamarca">Catamarca</option>
                        <option value="Misiones">Misiones</option>
                        <option value="Chaco">Chaco</option>
                        <option value="Tierra del fuego">Tierra del fuego</option>
                        <option value="Entre rios">Entre Rios</option>
                        <option value="La Pampa">La Pampa</option>
                        <option value="Santa Fe">Santa Fe</option>
                        <option value="Mendoza">Mendoza</option>
                        <option value="Salta">Salta</option>
                        <option value="Santa Cruz">Santa Cruz</option>
                        <option value="Rio Negro">Rio Negro</option>
                        <option value="Chubut">Chubut</option>
                        <option value="Neuquen">Neuquen</option>
                        <option value="La Rioja">La Rioja</option>
                        <option value="San Juan">San Juan</option>
                        <option value="Santiago del Estero">Santiago del Estero</option>
                        <option value="San Luis">San Luis</option>
                        <option value="Formosa">Formosa</option>
                        <option value="Corrientes">Corrientes</option>
                        {/* Agrega más opciones según tus requisitos */}
                      </select>
                    </label>
                  </div>
                )}
                <button className='checkPay' type="submit">Pasar a Pagar</button>
              </form>
            </div>
            
              </> : 
              
              (
              <div className='total-price'>
                <table>
                <tr>
                 <td>Subtotal</td>
                 <td>${totalAmount}</td>
      
               </tr>
            <tr>
              <td>Envio</td>
              <td>Gratis</td>
      
            </tr>
      
            <tr>
              <td>Total</td>
              <td>${totalAmount}</td>
              
            </tr>
            <tr className='checkout'>
              <td><button onClick={handleCheckout2}>Checkout</button></td>
            </tr>
                </table>
                
              </div>
             )}
       </div>
              
       </>
       :
       <>

        <div className='cart-vacio'>
          <h1>Carrito vacio</h1>
          <p>No tienes productos seleccionados,</p>
          <Link to={'/productos'}>
          <button className='buttonCart'>Ir a tienda</button>
          </Link>
        </div>
        </>

     }
         
    </Container>
  )
}


const Container = styled.div`

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.alert1{
  display: flex;
  gap: 4px;
  
}

.alert2{
  font-size: 20px;
  color: #ff523b;
}

.alertcheck{
  font-size: 15px;
  font-weight: 600;
}

.titleCart{
  text-transform: uppercase;
  padding-left: 20px;
}


.checkPay{
  background-color: #ff523b;
  padding: 10px;
  border-radius: 10px;
  color: white;
  width: 100%;
  max-width: 300px;
  
}

.envios{
  display: flex;
  flex-direction: column;
  gap: 13px;
}
.inputCheck2{
  border: 1px solid black;
  width: 340px;
  height: 50px;
}

.inputCheck{
  border: 1px solid black;
  
}

.checkout-form{
  margin: 10px;
}

form{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cart-vacio{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.buttonCart{
  background-color: #ff523b;
  padding: 10px;
  border-radius: 10px;
  color: white;
}

.checkout{
  border-top: 3px solid #ff523b;
}

.checkout button{
  background-color: #ff523b;
  padding: 10px;
  border-radius: 10px;
  color: white;
}

.total-price{
  display: flex;
  justify-content: flex-end;
}

.total-price table{
  border-top: 3px solid #ff523b;
  width: 100%;
  max-width: 400px;
}

.td:last-child{
  text-align: right;
}

th:last-child{
  text-align: right;
}



.cart-page{
  margin:80px auto;
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

table{
  width: 100%;
  
  border-collapse: collapse;
}

.cart-info{
  display: flex;
  flex-wrap: wrap;
}

th{
  text-align: left;
  padding: 5px;
  color: #fff;
  background-color:#ff523b;
  font-weight: normal;
}

td{
  padding: 10px 5px;
  padding-right: 11px;
}

td input{
  width: 50px;
  height: 40px;
  padding: 15px;
  outline: none;
  
}

td a{
  color: #ff523b;
  font-size: 15px;
}


td img{
  width: 170px;
  height: 200px;
  margin-right: 10px;
}

.input{
  border: solid 1px black;
  width: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.botoms{
  padding-right: 4px;
}


`

export default Cart