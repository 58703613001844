import React, {createContext,useState} from "react";
import axios from 'axios';
import { useEffect } from "react";
import {toast , ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


export const CartContex = createContext(null);
const URL = 'https://nutrinorth.up.railway.app/products/';

// const getDefaultCart = () => {
//     let cart = {}
//     for(let i = 1; i<70;i++){
//         cart[i]=0
//     }
//     return cart
// };


const getDefaultCart = () => {
  let cart = {}
  for (let i = 1; i < 40; i++) {
      // Solo agregar productos al carrito si no existen previamente
      if (!cart[i]) {
          cart[i] = 0;
      }
  }
  return cart;
};


export const CartContextProvider = (props) => {
    const [cartItems, setCartItems] = useState(getDefaultCart());
    const [payAumount,setPayAumount] = useState(0)
    const [user, setUser] = useState(null);
    const [products , setProducts] = useState([])
    const cartItemCount = Object.values(cartItems).reduce((acc,count)=> acc + count, 0)
    const [admin, setAdmin] = useState(false);//se utiliza para saber si el usuario es un admin, por defecto el hook se encuentra en falso
    const AdminChanger = (value) => setAdmin(value);
    const [logged, setLogged] = useState(0);//este hook es para saber si hay un usuario logeado en la pagina
    const loggedChanger = (value) => setLogged(value);

    useEffect(() => {
      getProducts();
      
    }, []);


    // useEffect(() => {
    //   const storedCart = localStorage.getItem('cartItems');
    //   if (storedCart) {
    //     setCartItems(JSON.parse(storedCart));
    //   }
    // }, []);

    useEffect(() => {
      const storedCart = localStorage.getItem('cartItems');
      if (storedCart) {
        // Parsear el carrito almacenado
        const parsedCart = JSON.parse(storedCart);
    
        // Asegurarse de que todos los ítems estén presentes en el carrito, inicializando los que faltan con 0
        const updatedCart = { ...getDefaultCart(), ...parsedCart };
    
        setCartItems(updatedCart);
      }
    }, []);

    const getProducts = async () => {
        const res = await axios.get(URL)
        setProducts(res.data)
    }

 

  const getTotalCartAmount = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = products.find((product) => product.id === Number(item));
  
        // Verificar si itemInfo es undefined antes de intentar acceder a sus propiedades
        if (itemInfo && itemInfo.precio) {
          totalAmount += cartItems[item] * itemInfo.precio;
        } else {
          console.error(``);
        }
      }
    }
    return totalAmount;
  };




  const addToCart = async (ItemId) => {
    try {
      const response = await axios.get(`https://nutrinorth.up.railway.app/products/book${ItemId}?f=book`);
      const productData = response.data;
  
      if (productData === "StockOut") {
        alert("Empty product");
      } else {
        const updatedCart = { ...cartItems, [ItemId]: cartItems[ItemId] + 1 };
        setCartItems(updatedCart);
  
        const productInfo = products.find((product) => product.id === Number(ItemId));
      const productName = productInfo.nombre;
      const productImage = productInfo.img;

      toast.success(
        <>
        
          <div className="d-flex">
            <img src={productImage} alt={productName} style={{ width: '50px', height: '50px' }} />
          </div>
          <div>
            Agregando {productName} al carrito
          </div>
         
        </>,
        {
          position: 'top-right',
        }
      );

  
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));
      }
    } catch (error) {
      console.error("Error al agregar al carrito:", error.message);
    }
  };
  


const removeFromCart = async (itemId) => {
    await axios
      .get(`https://nutrinorth.up.railway.app/products/book${itemId}?f=unbook`)
      .then(({ data }) => {
        setCartItems((prev) => ({
          ...prev,
          [itemId]: Math.max(0, prev[itemId] - 1),
        }));
        toast.warning( `Producto eliminado del carrito `,{
                        position:'top-right',
                    })  
        
      })
      .catch((error) => {
        console.log(error.message);
      });

      const updatedCart = { ...cartItems, [itemId]: Math.max(0, cartItems[itemId] - 1) };
      setCartItems(updatedCart);
      localStorage.setItem('cartItems', JSON.stringify(updatedCart));

    

      
    };
  
    const removeFromCart2 = async (itemId) => {
      await axios
        .get(`https://nutrinorth.up.railway.app/products/book${itemId}?f=unbook`)
        .then(({ data }) => {
          setCartItems((prev) => ({
            ...prev,
            [itemId]: 0, // Establecer la cantidad directamente en 0
          }));
        })
        .catch((error) => {
          console.log(error.message);
        });
    
      const updatedCart = { ...cartItems, [itemId]: 0 }; // Establecer la cantidad directamente en 0
      setCartItems(updatedCart);
      localStorage.setItem('cartItems', JSON.stringify(updatedCart));
    };


 

   

  
    const contextValue = {removeFromCart2,setUser,user,admin,AdminChanger,loggedChanger,logged,cartItemCount,cartItems, addToCart,getTotalCartAmount, removeFromCart , setPayAumount,payAumount}
    return( <>
        <CartContex.Provider value={contextValue}>
            {props.children}
            <ToastContainer autoClose={2000} 
            theme="dark"
            pauseOnFocusLoss={false}/>
        </CartContex.Provider>
        </>
    )



}

