import './App.css';
import { CartContextProvider } from './context/CartContex';
import { BrowserRouter, Routes ,Route, Navigate } from 'react-router-dom';
import Shop from './pages/shop/Shop';
import ItemDetailContainer from './containers/ItemDetailContainer';
import Cart from './pages/cart/Cart';
import Navbar from './components/navbar/Navbar';
import Home from './pages/home/Home';
import Footer from './components/footer/Footer';
import Register from './pages/register/register';
import Login from './pages/login/login';
import EditAdmin from './pages/admin/editProfile/editProfileAdmin';
import { ProductsEdit } from './pages/admin/editProduct/ProductsEdit'
import Order from './pages/cart/Order'
import ScrollToTop from './components/scrollTop/ScrollToTop';
import TermAndConditions from './pages/info/TermAndConditions';
import About from './pages/info/About';
import Politica from './pages/info/Politica';
// import Like from './pages/like/Like';
import Contact from './pages/contact/Contact';
import OrderFailed from './pages/cart/OrderFailed';
import ErrorBoundary from './ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
    <div className="App">
      <CartContextProvider>
           <BrowserRouter>
           <ScrollToTop/>
                 <Navbar/>
                 <br></br>
     <br></br>

     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
                  <Routes>
                    <Route path='/' element={<Home/>} exact></Route>
                    <Route path='/productos' element={<Shop/>}></Route>
                    <Route path='/detail/:id' element={<ItemDetailContainer/>}/>
                    <Route path='/cart' element={<Cart/>}/>
                    <Route path="/register" element={<Register />}/>
                    <Route path="/login" element={<Login />}/>
                    <Route path="/editAdmin" element={<EditAdmin />}/>
                    <Route path="/editInventory" element={<ProductsEdit />}/>
                    <Route path="/terminos" element={<TermAndConditions />}/>
                    <Route path="/compra-exitosa" element={<Order/>} />
                    <Route path="/compra-fallida" element={<OrderFailed/>} />
                    <Route path="/sobre-nosotros" element={<About/>} />
                    <Route path="/politica" element={<Politica/>} />
                    <Route path="/contacto" element={<Contact/>} />
                    {/* <Route path="/like" element={<Like/>} /> */}
                    <Route path='*' element={<Navigate to='/' />} />
                  </Routes>
               <Footer/>
              </BrowserRouter>
      </CartContextProvider>
    </div>
    </ErrorBoundary>
  );
}

export default App;
