import React from "react";
import { useNavigate } from "react-router-dom";//es para redireccionar
import axios from "axios";//es para poder hacer peticiones
import { useState, useEffect } from "react";

import { useContext } from "react";
import { CartContex } from "../../context/CartContex";
import styled from "styled-components";

const URI = 'https://nutrinorth.up.railway.app/users/';//ruta para hacer las peticiones 

const Login = () => {
    const context = useContext(CartContex);
    const navigate = useNavigate();
    const navigateRegister = () => {//redirige hacia el registro
        navigate(`/register`);
    }



    const navigateShopAddtoCart = () => {//redirige a la pagina principal
        navigate(`/`);
    }

    const navigateEditInventory = () => {//redirige hacia el editor del inventario
        navigate(`/editInventory`);
    }

    const [entrada, SetEntrada] = useState(''); //este hook representa la entrada del usuario 
    const [entradaP, SetEntradaP] = useState('');//este es el hook para el password 
    const [users, setUsers] = useState([]);//aqui se almacenan los usuarios para comparar con la entrada
    
   

    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = async () => {
        try {
            const res = await axios.get(URI);
           
      
            // Actualiza el estado con los usuarios obtenidos
            setUsers(res.data);
          } catch (error) {
            console.error('Error fetching users:', error);
          }
      };



      const navigateLogin = () => {//redirige al login
        navigate(`/login`);
    }

    const compare = async (e) => {
      e.preventDefault();
    
      try {
        const foundUser = users.find((user) => user.nombre === entrada && user.contrasena === entradaP);
    
        if (foundUser) {
          context.setUser(foundUser);
    
          if (entrada === 'admin') {
            context.AdminChanger(true);
          }
    
          context.loggedChanger(true);
          navigateShopAddtoCart();
        } else {
          navigateLogin();
        }
      } catch (error) {
        console.error('Error during login:', error);
      }
    };

    return (
        <Container>
            <div className="wrapper">
               <div className="form-wrapper sign-in">
                <form onSubmit={compare}>
                <h2>Login</h2>
                <div className="input-group">
                    <input name="user" id="user"  type="text" value={entrada} onChange={(e)=> SetEntrada(e.target.value)} />
                    <label>Usuario</label>
                </div>
                <div className="input-group">
                    <input name="user" id="pass"  type="password" value={entradaP} onChange={(e)=> SetEntradaP(e.target.value)} />
                    <label>Contraseña</label>
                </div>
                <button type="submit" onClick={(e) => { //al darle click al si compare es true osea que el login tuvo exito
                    //   e.preventDefault();
                      if(compare()) //preguntasi la comparacion tuvo exito
                       {
                        if (entrada === 'admin') //si el nombre de la entrada 
                       {
                            navigateEditInventory(); //rediricciona al editor del inventario 
                             context.AdminChanger(true);//se activa el admin dentro del context
                        }
                         else 
                             navigateShopAddtoCart();//de lo contrario es un usuario normal por lo que envia al shop con los botones
                             context.loggedChanger(true);//se cabia a true el hook que pone si esta logeado el usuario
                       }
                        else
                             navigateLogin() }}>Login</button>
                      <div className="signUp-Link">
                        <p>No tienes una cuenta?,<span onClick={navigateRegister}>Crear cuenta</span></p>  
                      </div>       
                </form>
               </div>
            </div>    
        </Container>

       

    )
}


const Container = styled.div`
  
display: flex;
justify-content: center;
align-items: center;
min-height: 70vh;

.wrapper{
    position: relative;
    width: 400px;
    height: 500px;
    padding: 40px;
    background: red;
    background-color: #000;
    box-shadow: 0 0 50px var(--bittersweet);
    border-radius: 20px;
}

.from-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

}


h2{
    font-size: 30px;
    color: white;
    text-align: center;
}

.input-group{
    position: relative;
    margin: 30px 0;
    border-bottom: 2px solid #fff;

}

.input-group label{
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: .5s;
}

.input-group input{
   width: 320px;
   height: 40px;
   font-size: 16px;
   color: #fff;
   padding: 0 5px;
   background: transparent;
   border: none;
   outline: none;
}

.input-group input:focus~label,
.input-group input:valid~label{
    top: -5px;
}

button{
    position: relative;
    width: 100%;
    height: 40px;
    background: var(--bittersweet);
    box-shadow: 0 0 10px var(--bittersweet);
    font-size: 16px;
    color: black;
    font-weight: 500;
    cursor: pointer;
    border-radius: 30px;
    border: none;
    outline: none;
}

.signUp-Link{
    font-size: 14px;
    text-align: center;
    margin: 15px 0;
}

.signUp-Link p{
  color: white;
}

.signUp-Link p span{
    color: var(--bittersweet);
    cursor: pointer;
    font-weight: 500;
}

.signUp-Link p span:hover{
  text-decoration: underline;
}

`

export default Login;