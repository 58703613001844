import React from 'react'
import styled from 'styled-components'

const TermAndConditions = () => {
  return (
    <Container>
    <h1>Términos y Condiciones de NutriNorth</h1>

    <p>Bienvenido a NutriNorth, tu destino para obtener los mejores suplementos fitness. Te invitamos a leer detenidamente estos Términos y Condiciones antes de utilizar nuestros servicios.</p>

    <h4>1. Aceptación de Términos</h4>
    <p>Al acceder y utilizar los servicios de NutriNorth, aceptas cumplir con estos términos y condiciones. Si no estás de acuerdo con alguna parte de los términos, te pedimos que no utilices nuestros servicios.</p>

    <h4>2. Información Personal</h4>
    <p>Para proporcionarte un servicio personalizado, es posible que recopilemos información personal, como nombre, dirección y correo electrónico. Puedes revisar nuestra Política de Privacidad para obtener más detalles sobre cómo manejamos tus datos.</p>

    <h4>3. Uso Apropiado</h4>
    <p>Te comprometes a utilizar nuestros servicios solo con fines legales y apropiados. No deberás utilizar nuestros servicios de manera que pueda dañar, inhabilitar, sobrecargar o perjudicar a NutriNorth o a terceros.</p>

    <h4>4. Comunicación</h4>
    <p>Nos reservamos el derecho de comunicarnos contigo a través de correos electrónicos o mensajes relacionados con tus pedidos y promociones especiales. Puedes optar por no recibir ciertas comunicaciones siguiendo las instrucciones proporcionadas en nuestros mensajes.</p>

    {/* Agrega más secciones según sea necesario */}

    <h3>Contáctanos</h3>
    <p>Si tienes alguna pregunta sobre estos términos y condiciones, no dudes en ponerte en contacto con nosotros a través de nuestro número de contacto: 11 5139 9129 o visitando nuestra cuenta de Instagram: @nutrinorthoficial.</p>
  </Container>
  )
}


const Container = styled.div`
margin: 120px;


@media screen and (max-width:700px){
   margin: 40px;
}


`

export default TermAndConditions