import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { CartContex } from '../../context/CartContex'
import styled from 'styled-components'
import { FaCartPlus,FaEye  } from "react-icons/fa";
// import {AiOutlineHeart} from 'react-icons/ai'


const ItemProduct = (props) => {
  
     const {id, nombre,marca,precio,categoria,img} = props.data
    const {addToCart} = useContext(CartContex)
     
    
    const handleAddToCartClick = () => {
      addToCart(id);
    };

    // const handleAddToCartClick2 = () => {
    //   addToLikes(nombre);
    // };


  
  return (
    <Container>
    
    <ul className="product-list">

            <li className="product-item">
              <div className="product-card" tabindex="0">

                <figure className="card-banner">
                  <img src={img} width="312" height="350" loading="lazy"
                    alt="Running Sneaker Shoes" className="image-contain"/>

                  <div className="card-badge">New</div>

                  <ul className="card-action-list">

                    <li classN="card-action-item">
                      <button onClick={handleAddToCartClick} className="card-action-btn" aria-labelledby="card-label-1">
                      <FaCartPlus className='opcion'/>
                      </button>

                      
                    </li>

                    {/* <li className="card-action-item">
                      <button onClick={handleAddToCartClick2} className="card-action-btn" aria-labelledby="card-label-2">
                      <AiOutlineHeart className='opcion'/>
                      </button>

                      
                    </li> */}

                    <li className="card-action-item">
                      <Link to={`/detail/${id}`}>
                      <button className="card-action-btn" aria-labelledby="card-label-3">
                      <FaEye className='opcion'/>
                      </button>
                      </Link>

                      
                    </li>

                    

                  </ul>
                </figure>

                <div class="card-content">

                  <div class="card-cat">
                    <span  class="card-cat-link">{marca}</span> /
                    <span  class="card-cat-link">{categoria}</span>
                  </div>

                  <h3 class="h3 card-title">
                    <Link to={`/detail/${id}`} href="#">{nombre}</Link>
                  </h3>

                  <data class="card-price" value="180.85">${precio}</data>

                </div>

              </div>
            </li>
            
            
     </ul>
    
    
    </Container>
  )
}


const Container = styled.div`

@media screen and (min-width:1200px) {
  .product-card{
    width: 300px;
  }
}



.product-card{
    width: 330px;
  }

.opcion{
  font-size: 20px;
}

.product .section-title {
  text-align: center;
  margin-bottom: 25px;
}

.filter-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.filter-btn {
  color: var(--onyx);
  padding: 10px 16px;
  font-family: var(--ff-roboto);
  font-size: var(--fs-7);
  font-weight: var(--fw-500);
  border: 1px solid var(--gainsboro);
  border-radius: 30px;
}

.filter-btn.active {
  background: var(--bittersweet);
  color: var(--white);
  border-color: var(--bittersweet);
}

.product-list {
  display: grid;
  gap: 50px 25px;
}



.card-banner {
  background: hsl(200deg 3.84% 86.23%);
  position: relative;
  height: 200px;
  overflow: hidden;
}

.image-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  transition: var(--transition-2);
}

.product-card:is(:hover, :focus) .image-contain { transform: scale(1.1); }

.card-badge {
  position: absolute;
  top: 20px;
  left: 20px;
  background: var(--bittersweet);
  color: var(--white);
  padding: 5px 15px;
  font-family: var(--ff-roboto);
  font-size: 16px;
  border-radius: 25px;
}

.card-action-list {
  position: absolute;
  top: 20px;
  right: -20px;
  opacity: 0;
  transition: var(--transition-1);
}

.product-card:is(:hover, :focus) .card-action-list {
  right: 20px;
  opacity: 1;

}

.card-action-item { position: relative; }

.card-action-item:not(:last-child) { margin-bottom: 10px; }

.card-action-btn {
  background: var(--white);
  color: var(--rich-black-fogra-29);
  font-size: 15px;
  padding: 10px;
  border-radius: 50%;
  transition: var(--transition-1);
}



.card-action-btn:is(:hover, :focus) {
  background: var(--bittersweet);
  color: var(--white);
}



.card-action-tooltip {
  position: absolute;
  top: 50%;
  right: calc(100% + 5px);
  transform: translateY(-50%);
  width: max-content;
  background: var(--black_90);
  color: var(--white);
  font-family: var(--ff-roboto);
  font-size: 16px;
  padding: 4px 8px;
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition-1);
}

.card-action-btn:is(:hover, :focus) + .card-action-tooltip { opacity: 1; }

.card-content {
  padding: 24px 15px 0;
  text-align: center;
}

.card-cat {
  font-family: var(--ff-roboto);
  color: var(--onyx);
  font-size: var(--fs-10);
  margin-bottom: 12px;
}

.card-cat-link {
  display: inline-block;
  color: inherit;
  transition: var(--transition-1);
}

.card-cat-link:is(:hover, :focus) { color: var(--bittersweet); }

.product-card .card-title { margin-bottom: 12px;
 font-size: 21px;
}

.product-card .card-title > a {
  color: inherit;
  transition: var(--transition-1);
}

.product-card .card-title > a:is(:hover, :focus) { color: var(--bittersweet); }

.card-price {
  color: var(--bittersweet);
  font-family: var(--ff-roboto);
  font-weight: var(--fw-600);
  font-size: 19px;
}

.card-price del {
  color: var(--gray-x-11-gray);
  margin-left: 8px;
}


`




export default ItemProduct