import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Politica = () => {
  return (
    <Container>
      <h1>Política de Privacidad de NutriNorth</h1>

      <p>En NutriNorth, nos comprometemos a proteger tu privacidad y a utilizar tu información de manera responsable. A continuación, se detalla nuestra Política de Privacidad.</p>

      <h4>1. Información Recopilada</h4>
      <p>Recopilamos información personal, como nombre, dirección, correo electrónico y número de teléfono, cuando te registras en nuestros servicios o realizas una compra. También podemos recopilar información no personal, como datos de navegación y preferencias.</p>

      <h4>2. Uso de la Información</h4>
      <p>Utilizamos la información recopilada para procesar tus pedidos, proporcionar servicios personalizados, mejorar nuestros productos y servicios, y comunicarnos contigo de manera efectiva. No compartiremos tu información con terceros sin tu consentimiento expreso.</p>

      <h4>3. Seguridad</h4>
      <p>Implementamos medidas de seguridad para proteger tu información personal contra accesos no autorizados o divulgación. Utilizamos tecnologías seguras para garantizar la transmisión segura de datos.</p>

      <h4>4. Cookies</h4>
      <p>Podemos utilizar cookies y tecnologías similares para mejorar la experiencia del usuario y personalizar el contenido. Puedes ajustar la configuración de las cookies en tu navegador según tus preferencias.</p>

      {/* Agrega más secciones según sea necesario */}

      <h3>Contáctanos</h3>
      <p>Si tienes alguna pregunta sobre nuestra política de privacidad, no dudes en ponerte en contacto con nosotros a través de nuestro número de contacto: 11 5139 9129 o visitando nuestra cuenta de Instagram: @nutrinorthoficial. O envienos un mail en <span><Link>contacto</Link></span></p>
    </Container>
  )
}

const Container=styled.div`
margin: 100px;


@media screen and (max-width:700px){
   margin: 40px;
}

`

export default Politica