import React from "react";
import { useState } from "react";
import axios from 'axios';
import styled from "styled-components";
import { Link } from "react-router-dom";

const URI = 'https://nutrinorth.up.railway.app/products/';//aqui se hacen las peticiones 

export const Product = (props) => {
    const { id, nombre, precio ,img} = props.data; //aqui se guardan los datos de los productso
    const [priceHook, setPrice] = useState(); //hook para el precio del producto
   
    

    const update = async (e) => {//funcion para actualizar un producto a partir del id 
        e.preventDefault();
        await axios.put(URI+id+'/', { precio: priceHook });
    }

    

    return (
        <Container>
                <div className="card" style={{width:'18rem'}}>
                <img src={img} className="card-img-top" alt="..."/>
                <div className="card-body">
                  <h5 className="card-title">{nombre}</h5>
                  
                  <Link href="#" class="btn btn-primary">{precio}</Link>
                </div>
              </div>
          
            <form onSubmit={update} action="/auth" method="post"> {/*al momento de enviar el formulario se llama a la funcion update para actualizar el producto */}
                    <input className="inputPrice"
                    onChange={ (e) => setPrice(e.target.value)} /* se guarda el valor ingresado del input dentro de price */
                    type="text" name="pass" id="pass" placeholder="Nuevo precio"/>
                    <input type="submit" className="btn-login" value="Editar precio" />
            </form>
     

     
    
    
    </Container>
    );
};

const Container = styled.div`
  
  

 .inputPrice{
  border:1px solid black;
  margin:10px;
 }

 .btn-login{
  margin: 10px;
  font-size: 19px;
  font-weight: 600;
 }

`

export default Product

