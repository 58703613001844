import React, { useContext } from "react";
import styled from "styled-components";
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css';
import { useEffect,useState } from "react";
import axios from "axios";
import { CartContex } from "../../context/CartContex";
import { Link } from "react-router-dom";


const URL = "https://nutrinorth.up.railway.app/products/";

const CarouselOffer = () => {
  const [products, setProducts] = useState([]);
  const {addToCart} = useContext(CartContex)
  const [comboProducts, setComboProducts] = useState([]);
  const [oferProducts, setOferProducts] = useState([]);

  useEffect(() => {
    getProducts();
    getComboProducts();
    getOferProducts();
  }, []);

  const getProducts = async () => {
    const res = await axios.get(URL);
    setProducts(res.data);
    
  };

  const getComboProducts = async () => {
    try {
      const res = await axios.get('https://nutrinorth.up.railway.app/products');
  
      // Filtra los productos que tienen la categoría "combos"
      const comboProducts = res.data.filter(product => product.categoria === 'combos');
  
      setComboProducts(comboProducts);
     
    } catch (error) {
      console.error('Error al obtener productos de combos:', error.message);
    }
  };


  const getOferProducts = async () => {
    try {
      const res = await axios.get('https://nutrinorth.up.railway.app/products');
  
      // Filtra los productos que tienen la categoría "combos"
      const comboProducts = res.data.filter(product => product.oferta === 'oferta');
  
      setOferProducts(comboProducts);
     
    } catch (error) {
      console.error('Error al obtener productos de ofertas:', error.message);
    }
  };

  
  const handleAddToCart = (itemId) => {
    addToCart(itemId);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1400 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1400, min: 990 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 990, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };


  
  
  return (
    <CarouselContainer>
      <h1 className="title-offer">Ofertas web</h1>
      <Carousel  responsive={responsive}
      autoPlay={true}
       showDots={true}
       infinite={true}
       autoPlaySpeed={3900}
      >

        {oferProducts.map((product)=> {
          return <>
                 <div id="ofertas" className="card-width">
             <Link to={`/detail/${product.id}`}>   
            <div className="img">
              <img src={product.img} width='200px' alt="Proteina" className="product--image"/>
              <h2 className="title-card">{product.nombre} </h2> 
              <p className="price">${product.precio}</p>
            </div>
            </Link>  
            <p className='content-button'>
                <button onClick={() => handleAddToCart(product.id)} className="card-button">Agregar al carrito</button>
            </p>

            </div>
              </>
        })}
       
        
      </Carousel>

      <br/>
      <br/>
      <br/>

      <h1 className="title-offer">Combos</h1>



      <Carousel  responsive={responsive} 
       autoPlay={true}
       
       infinite={true}
       autoPlaySpeed={5500}
       showDots={true}
      >
           {comboProducts.map((product)=> {
          return <>
                 <div id="combos"  className="card-width">
             <Link to={`/detail/${product.id}`}>   
            <div className="img">
              <img src={product.img} width='200px' alt="Proteina" className="product--image"/>
              <h2 className="title-card">{product.nombre} </h2> 
              <p className="price">${product.precio}</p>
            </div>
            </Link>  
            <p className='content-button'>
                <button onClick={() => handleAddToCart(product.id)} className="card-button">Agregar al carrito</button>
            </p>

            </div>
              </>
        })}
      
       
      </Carousel>
    
      
    </CarouselContainer>
  );
};

const CarouselContainer = styled.div`

@media screen and (min-width: 1500px) and (max-width: 3000px)  {
    
  padding: 60px;

} 

@media screen and (max-width: 800px){

  padding: 10px;


} 

.react-multi-carousel-item{
  margin-bottom: 10px;
}

.img{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.react-multiple-carousel__arrow {
    
     z-index: 2; 
   
}


.title-offer{
    padding: 10px;
    text-transform: uppercase;
}

.product--image {
  width: 100%;
  height: 16em;
  object-fit: cover;
  
  
}

.card-width {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 380px;
  margin: auto 2rem;
  text-align: center;
  font-family: arial;
 
}

.title-card{
    font-size: 20px;
    color: grey;
    padding-top: 10px;
}

.price {
  color: black;
  font-size: 22px;
  font-weight: 600;
}

.card-width button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

.card button:hover {
  opacity: 0.7;
}








`;

export default CarouselOffer;
