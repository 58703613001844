import React from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useState } from "react";

const URIADMIN = 'https://nutrinorth.up.railway.app/users/11/'; //aqui se hacen las peticiones para el admin

const EditAdmin = () => {
    const [contrasena, setPassword] = useState('');//se guarda la contrasena
    
    const [telefono, setTelephone] = useState('');//nuevo telefono
    const [email, setEmail] = useState('');//nuevo email
    const navigate = useNavigate();
    const navigateShop = () => {
        navigate(`/editInventory`);//redirecciona a editar el inventario
    }

    const update = async (e) => { //se hace una peticion para actualizar al admin
        e.preventDefault();
        await axios.put(URIADMIN, { contrasena: contrasena, telefono: telefono, email: email });
        navigateShop();
    }


    return (
        <div className="register-form"> {/*este es el formulario que pide la info del admin para poder cambiar su info */}
            <h2>Edit Profile</h2>
            <form onSubmit={update} action="/auth" method="post">
                <input 
                value={contrasena}
                onChange={ (e) => setPassword((e.target.value))}
                type="password" name="pass" id="pass" placeholder="password"/>
                
                <input 
                value={telefono}
                onChange={ (e) => setTelephone(e.target.value)}
                type="text" name="pass" id="pass" placeholder="telephone"/>
                <input 
                value={email}
                onChange={ (e) => setEmail(e.target.value)}
                type="text" name="pass" id="pass" placeholder="email"/>
                <input type="submit" className="btn-login" value="Edit" />
            </form>
        </div>
    )
}

export default EditAdmin;